import * as React from 'react';
import { useIsAuthenticated } from 'react-auth-kit';
import { Navigate, useLocation } from 'react-router-dom';

// workaround for "Cannot update a component (`AuthProvider`) while rendering
// a different component <component>."
// per https://github.com/react-auth-kit/react-auth-kit/issues/1023#issuecomment-1379461046
export interface PrivateRouteProps {
  children: React.ReactNode;
  loginPath: string;
};

function PrivateRoute({ children, loginPath }: PrivateRouteProps): any {
  const isAuthed = useIsAuthenticated();
  const location = useLocation();

  if (isAuthed()) {
    return children;
  }

  return <Navigate to={loginPath} state={{from: location}} replace/>
};

export default PrivateRoute;
