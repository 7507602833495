import React from 'react';
import { Button } from "@mui/material";
import { useFilterDispatchContext } from '../../contexts/FilterContext';
import { useLeagueContext } from '../../contexts/LeagueContext';

function ResetButton() {
  const filterDispatchContext: any = useFilterDispatchContext();

  const leagueContext: any = useLeagueContext();
  const league: string = leagueContext?.selectedLeague?.toLowerCase() || 'nba';

  const resetFilters = () => {
    filterDispatchContext({
      type: 'resetFilters',
      league
    });
  };

  return (
    <Button variant="outlined" onClick={resetFilters}>
      Reset
    </Button>
  );
}

export default ResetButton;