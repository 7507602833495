import React from 'react';
import { Grid } from '@mui/material';
import { StatFilter } from './filter-stats-utils';
import './EliasStatInfo.scss';


export interface StatInfoProps {
  statFilter: StatFilter;
  displayYearInfo?: boolean
}

export function StatNameAndAbbr({ statFilter }: StatInfoProps) {
  return (
    <Grid item xs={2} className="stat-name-container">
      <span style={{ fontWeight: 'bold', marginRight: '10px' }}>{statFilter.abbr}</span>
      <span>{statFilter.name}</span>
    </Grid>
  );
}

export function StatExplanation({ statFilter }: StatInfoProps) {
  return (
    <span className="stat-explainer-container">{statFilter.explanation}</span>
  );
}

export function StatYear({ statFilter, displayYearInfo }: StatInfoProps) {
  return <Grid item className="stat-year-container">{displayYearInfo ? statFilter.year ?? '-' : ''}</Grid>;
}
