import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  LabelStyles,
  CheckboxStyles,
  SubLabelStyles,
} from './StyleOverrides';

interface IProps {
  label: string;
  subLabel?: string;
  labelPlacement?: 'top' | 'start' | 'bottom' | 'end';
  className?: string;
  selected?: boolean;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => void;
}

function ModalCheckbox({
  label,
  subLabel,
  labelPlacement,
  className = 'elias-checkbox',
  selected = false,
  onChange = () => {},
}: IProps) {
  return (
    <Box>
      <FormControlLabel
        className={className}
        control={
          <Checkbox
            style={CheckboxStyles}
            inputProps={{
              'aria-label': `${label}-checkbox`,
            }}
          />
        }
        label={<FormHelperText style={LabelStyles}>{label}</FormHelperText>}
        labelPlacement={labelPlacement ?? 'end'}
        checked={selected}
        onChange={onChange}
        data-testid={`${label}-checkbox`}
      />
      {subLabel && (
        <FormHelperText sx={SubLabelStyles}>{subLabel}</FormHelperText>
      )}
    </Box>
  );
}

export default ModalCheckbox;