import React, { useState } from 'react';
import { generatePath } from 'react-router';
import axios from 'axios';
import { Box, IconButton } from '@mui/material';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import StarOutlineRouted from '@mui/icons-material/StarOutlineRounded';
import StarRounded from '@mui/icons-material/StarRounded';
import LinkIcon from '@mui/icons-material/Link';
import { RecentSearchType } from "../../../api/types";
import API_URLS from '../../../api/api_urls';

const leagueRouteMap = Object({
  'ncaam': 'ncaambb',
  'nba': 'tools',
  'wnba': 'wnba',
});

function ActionsCell({ row }: { row: RecentSearchType}) {  

  const [favorite, setFavorite] = useState(row.favorite);

  const league = leagueRouteMap[row.league?.toLowerCase()];
  const routeFromApi = `/${league}/${row.finder}/results/:searchId`;
  const route = generatePath(routeFromApi, { searchId: row.id });

  const copyLink = () => {
    const host = window.location.origin;
    navigator.clipboard.writeText(`${host}${route}`);
  };

  const addSearchToFavorites = () => {
    const currentStatus = favorite;
    setFavorite(!favorite);
    axios.patch(`${API_URLS.search}/${row.id}/`, {
      id: row.id,
      favorite: !favorite,
      league: row.league,
      finder: row.finder,
    }).then(() => {
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      setFavorite(currentStatus);
    });
  };

  return (
    <Box>
      <IconButton onClick={addSearchToFavorites}>
        { favorite ? <StarRounded sx={{ color: '#175CD3' }} /> : <StarOutlineRouted sx={{ color: '#667085' }} /> }
      </IconButton>
      <IconButton  onClick={copyLink}>
        <LinkIcon/>
      </IconButton>
      <IconButton  href={route} target="_blank">
        <OpenInNewRoundedIcon />
      </IconButton>
    </Box>
  );
}

  export default ActionsCell;
