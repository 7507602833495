import Cookies from 'js-cookie';
import { datadogRum } from '@datadog/browser-rum';
import axios from 'axios';
import API_URLS from './api_urls';

export const setDatadogUser = (user: any, response?: any) => {
  if (user?.id) {
    try {
      datadogRum.setUser({
        id: user?.id,
        name: user?.full_name || user?.name || '',
        email: user?.email,
        username: user?.username,
        groups: user?.groups.map((g: any) => g.name),
        group_ids: user?.groups.map((g: any) => g.id),
      });
    } catch (e) {
      datadogRum.addError(e, {
        response: { user, headers: response?.headers }
      });
    }
  } else {
    datadogRum.clearUser();
  }
};

export const doLogout = async () => {
  axios.post(API_URLS.auth.logout);
  // let's try clearing the user session on _all_ logouts
  setDatadogUser({});
};

export const doLogin = async (
  username: string,
  password: string,
  success: any,
  failure: any
) => {
  const data = {username, password};
  axios.post(API_URLS.auth.login, data, {
    headers: {'X-CSRFToken': Cookies.get('csrftoken')}
  }).then(success).catch(failure);
};

export const doRefresh = async (
  refreshToken: string,
) => axios.post(API_URLS.auth.refreshToken, {refresh: refreshToken}, {
  headers: {'X-CSRFToken': Cookies.get('csrftoken')}
});
