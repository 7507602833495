import React from 'react';
import Typography from '@mui/material/Typography';

interface IProps {
  label: string;
  values: React.ReactNode[];
}

function FactCard({ label, values }: IProps) {
  const filteredValues = values.filter((f) => f);
  if (filteredValues.length === 0) {
    return <div />;
  }

  return (
    <>
      <Typography component="div" variant='body1' fontWeight="600" mt={2}>{label}</Typography>
      { filteredValues.map((value: React.ReactNode, index: number) => {
          if (typeof value === 'string') {
            return <Typography key={value?.toString()} component="div" variant='body1' mt={1}>{value}</Typography>
          }
          // eslint-disable-next-line react/no-array-index-key
          return <Typography key={index} component="div" variant='body1' mt={1}>{value}</Typography>
        })
      }
    </>
  );
}

export default FactCard;
