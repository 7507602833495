import { ColumnMappingType } from "../../../types/results";
import { SelectedStatFilterType } from "../../../types/statsFilter";
import { RESULT_COLUMNS } from "../../common/map-columns";
import { showHideColumns } from "../../common/results";

export const ungroupedColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.playerDisplayName,
  RESULT_COLUMNS.gameDate,
  RESULT_COLUMNS.gameType,
  RESULT_COLUMNS.result,
  RESULT_COLUMNS.gameStarted,
  RESULT_COLUMNS.offensiveRebounds,
  RESULT_COLUMNS.defensiveRebounds,
  RESULT_COLUMNS.pointsInPaint,
  RESULT_COLUMNS.pointsOffTurnovers,
  RESULT_COLUMNS.dunks,
  RESULT_COLUMNS.secondChancePoints,
  RESULT_COLUMNS.tripleDouble,
  RESULT_COLUMNS.disqualified,
  RESULT_COLUMNS.personalFouls,
  RESULT_COLUMNS.franchiseScore,
  RESULT_COLUMNS.opponentScore,
  RESULT_COLUMNS.scoreDifference,
  RESULT_COLUMNS.lastGameResult,
  RESULT_COLUMNS.franchiseSeasonGameNumber,
  RESULT_COLUMNS.franchiseWinPercentage,
  RESULT_COLUMNS.opponentWinPercentage,
  RESULT_COLUMNS.winPercentageDifference,
  RESULT_COLUMNS.pointsDifference,
  RESULT_COLUMNS.fieldGoalsDifference,
  RESULT_COLUMNS.freeThrowsDifference,
  RESULT_COLUMNS.threePointFieldGoalsDifference,
  RESULT_COLUMNS.totalReboundsDifference,
  RESULT_COLUMNS.personalFoulsDifference,
  RESULT_COLUMNS.turnoversDifference,
  RESULT_COLUMNS.stealsDifference,
  RESULT_COLUMNS.fieldGoalPercentageDifference,
  RESULT_COLUMNS.opponentRank,
  // handle divergent api fields...
  {
    ...RESULT_COLUMNS.minutesDisplay,
    field: 'minutes',
  },
  RESULT_COLUMNS.points,
  RESULT_COLUMNS.totalRebounds,
  RESULT_COLUMNS.assists,
  RESULT_COLUMNS.fieldGoalsMade,
  RESULT_COLUMNS.fieldGoalAttempts,
  RESULT_COLUMNS.fieldGoalPercentage,
  RESULT_COLUMNS.threePointFieldGoalsMade,
  RESULT_COLUMNS.threePointFieldGoalAttempts,
  RESULT_COLUMNS.threePointFieldGoalPercentage,
  RESULT_COLUMNS.freeThrowsMade,
  RESULT_COLUMNS.freeThrowAttempts,
  RESULT_COLUMNS.freeThrowPercentage,
  RESULT_COLUMNS.steals,
  RESULT_COLUMNS.blocks,
  RESULT_COLUMNS.turnovers,
  RESULT_COLUMNS.pointsFirstHalf,
  RESULT_COLUMNS.opponentPointsFirstHalf,
  RESULT_COLUMNS.pointsFirstHalfDifference,
  RESULT_COLUMNS.pointsSecondHalf,
  RESULT_COLUMNS.opponentPointsSecondHalf,
  RESULT_COLUMNS.pointsSecondHalfDifference,
];

export const ungroupedTeamColumnMapping: ColumnMappingType[] = ungroupedColumnMapping.map(col => {
  const { field } = col;

  // fields to remove
  if (['score_difference', 'minutes'].includes(field)) {
    return {
      ...col,
      field: 'remove',
    }
  }

  // fields to modify
  if (field === 'player__display_name') {
    return {
      ...col,
      field: 'franchise_display_name',
      headerName: 'Team',
    };
  }

  return col;
}).filter(col => col.field !== 'remove');

export const playerColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  {
    ...RESULT_COLUMNS.playerDisplayName,
    field: 'display_name',
  },
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const playerSeasonColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  {
    ...RESULT_COLUMNS.playerDisplayName,
    field: 'display_name',
  },
  RESULT_COLUMNS.season,
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const teamInstanceColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  // divergent api field name..handle it
  {
    ...RESULT_COLUMNS.franchiseName,
    headerName: 'Team',
    field: 'franchise_display_name',
  },
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const teamSeasonColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  // divergent api field name..handle it
  {
    ...RESULT_COLUMNS.franchiseName,
    headerName: 'Team',
    field: 'franchise_display_name',
  },
  RESULT_COLUMNS.season,
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const playerFranchiseColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  {
    ...RESULT_COLUMNS.playerDisplayName,
    field: 'display_name',
  },
  {
    ...RESULT_COLUMNS.franchiseName,
    headerName: 'Team',
  },
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const playerOpponentColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  {
    ...RESULT_COLUMNS.playerDisplayName,
    field: 'display_name',
  },
  RESULT_COLUMNS.opponentName,
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const teamOpponentColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  {
    ...RESULT_COLUMNS.franchiseName,
    headerName: 'Team',
    field: 'franchise_display_name',
  },
  RESULT_COLUMNS.opponentName,
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const playerFranchiseSeasonColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  {
    ...RESULT_COLUMNS.playerDisplayName,
    field: 'display_name',
  },
  {
    ...RESULT_COLUMNS.franchiseName,
    headerName: 'Team',
  },
  RESULT_COLUMNS.season,
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const playerOpponentSeasonColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  {
    ...RESULT_COLUMNS.playerDisplayName,
    field: 'display_name',
  },
  RESULT_COLUMNS.opponentName,
  RESULT_COLUMNS.season,
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const mapTeamColumns = (
  groupByColumn: string,
  localFilters: SelectedStatFilterType[],
) => {
  switch(groupByColumn) {
    case 'team':
      return teamInstanceColumnMapping;
    case 'team-season':
      return teamSeasonColumnMapping;
    case 'team-opponent':
      return teamOpponentColumnMapping;
    default:
      return showHideColumns(ungroupedTeamColumnMapping, localFilters, undefined);
  };
}

export const mapPlayerColumns = (
  groupByColumn: string,
  localFilters: SelectedStatFilterType[],
) => {
  switch (groupByColumn) {
    case 'player':
      return playerColumnMapping;
    case 'player-season':
      return playerSeasonColumnMapping;
    case 'player-franchise':
      return playerFranchiseColumnMapping;
    case 'player-opponent':
      return playerOpponentColumnMapping;
    case 'player-franchise-season':
      return playerFranchiseSeasonColumnMapping;
    case 'player-opponent-season':
      return playerOpponentSeasonColumnMapping;
    default:
      return showHideColumns(ungroupedColumnMapping, localFilters, undefined);
  };
}

export const mapColumns = (
  groupByColumn: string,
  localFilters: SelectedStatFilterType[],
  finderType: string = 'player',
) => {
  switch(finderType) {
    case 'player':
      return mapPlayerColumns(groupByColumn, localFilters);
    case 'team':
      return mapTeamColumns(groupByColumn, localFilters);
    default:
      return mapPlayerColumns(groupByColumn, localFilters);
  };
}

