import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import EliasBackButton from '../../../atoms/EliasBackButton/EliasBackButton';
import ROUTER_URLS from '../../../utils/router/urls';
import ResultsLoading from '../../../compounds/ResultsLoading/ResultsLoading';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import PlayerSpanFilters from '../../../compounds/PlayerSpanFilters/PlayerSpanFilters';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import PlayerSpanTable from '../../../compounds/PlayerSpanTable/PlayerSpanTable';
import { fetchNcaaPlayerSpanStats } from '../../../../api/ncaambb';
import { statsFilters, spanRangeOptions, PAGE_TITLE, PAGE_SUBTITLE } from './constants';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import { advancedModalFilterCategories } from '../constants';
import { ContextFilterType } from '../../../types/context';
import NcaaMbbPlayerFiltersLayout from '../../../compounds/ncaambb/NcaaMbbPlayerFiltersLayout';
import FinderStackLayout from '../../../compounds/FinderStackLayout/FinderStackLayout';
import withResultsLayout, { WithResultsLayoutProps } from '../../../compounds/ResultsWrapper/ResultsWrapper';
import { mapRows } from '../../../utils/ncaambb/span-finder/map-results';
import { mapColumns } from '../../../utils/ncaambb/span-finder/map-columns';
import { appendContextFilters } from '../../../utils/ncaambb/search';
import Header from '../../../compounds/Common/Header';
import EXTERNAL_URLS from '../../../utils/router/external_urls';

const LOADING_MESSAGE = 'Spans are data intensive and may take longer than other finders to produce results.';

interface IProps extends WithResultsLayoutProps {};

function PlayerSpanFinderResults({
  columnHeaders,
  resultsData,
  tableDataLoading,
  filtersLoading,
  error,
  handleDone,
  pageOrSortDataFetch
}: IProps) {

  const pageSpecificSearchEnabled = (context: ContextFilterType): boolean =>
    Boolean(context.spanLength) && Number(context.spanLength) > 1;

  const statsFilterPartial = <EliasStatsFilter statsFilterOptions={statsFilters} displayYearInfo={false} />;
  const bottomModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={advancedModalFilterCategories}
      filterType='advancedFilters'
    />
  );

  return (
    <Dashboard loading={filtersLoading}>
      <FinderStackLayout
        header={
          <Header
            title={PAGE_TITLE}
            subtitle={PAGE_SUBTITLE}
            backButton={
              <EliasBackButton route={ROUTER_URLS.ncaambb.playerSpanFinderSearch} />
            }
          />
        }
        topPanel={
          <NcaaMbbPlayerFiltersLayout
            handleDone={handleDone}
            statsFilterPartial={statsFilterPartial}
            pageSpecificSearchEnabled={pageSpecificSearchEnabled}
            apiUrls={API_URLS.ncaambb}
            bottomModalPartial={bottomModalPartial}
          >
            <PlayerSpanFilters spanRangeOptions={spanRangeOptions} />
          </NcaaMbbPlayerFiltersLayout>
        }
        bottomPanel={
          tableDataLoading || error ?
          <ResultsLoading error={error} loadingMessage={LOADING_MESSAGE} /> :
          <PlayerSpanTable
            data={resultsData?.results ?? []}
            dataCount={resultsData?.count ?? 0}
            dataMetaInfo={resultsData?.query?.back_to ?? []}
            columnData={columnHeaders || []}
            handleDone={pageOrSortDataFetch}
            linkUrls={EXTERNAL_URLS.NCAAM}
            explanationMessage={
              <Box sx={{ typography: 'body1' }}>
                <Link
                  component={RouterLink}
                  to={ROUTER_URLS.ncaambb.dataInfo}
                  sx={{
                    // color: (theme) => theme.palette.custom.darkGrey
                  }}
                >
                  See how far your search went back in time.
                </Link>
              </Box>
            }
          />
        }
      />
    </Dashboard>
  );
}

export default withResultsLayout(
  PlayerSpanFinderResults,
  ROUTER_URLS.ncaambb.playerSpanFinderResults,
  statsFilters,
  fetchNcaaPlayerSpanStats,
  mapColumns,
  mapRows,
  appendContextFilters,
  true, // translateNcaamFilters
);
