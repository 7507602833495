import React from 'react';
import { Stack } from '@mui/material';

interface IProps {
  header: React.ReactNode;
  topPanel: React.ReactNode;
  bottomPanel?: React.ReactNode;
};

function FinderStackLayout({ header, topPanel, bottomPanel }: IProps) {
  return (
    <Stack direction="column" marginY={5} marginX={4} spacing={3}>
      {header}
      {topPanel}
      {bottomPanel}
    </Stack>
  );
};

export default FinderStackLayout;