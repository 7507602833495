import theme from '../../utils/theme/theme';

const SearchButtonStyles = {
  ':disabled': {
    backgroundColor: theme.palette.disabled.button,
    color: theme.palette.custom.white,
  }
};

export default SearchButtonStyles;
