import React from 'react';
import Grid from '@mui/material/Grid';

interface IProps {
  error: Error,
}

function ResultsLoading({ error }: IProps) {
  return (
    <Grid item xs={12} textAlign='center'>
      <h3>{ error.message }</h3>
    </Grid>
  );
}

export default ResultsLoading;
