import React from 'react';
import ROUTER_URLS from '../../../utils/router/urls';
import Header from '../../../compounds/Common/Header';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import { statsFilters, PAGE_TITLE, PAGE_SUBTITLE } from './constants';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import { advancedModalFilterCategories, gameModalFilterCategories } from '../constants';
import { ContextFilterType } from '../../../types/context';
import WnbaTeamFiltersLayout from '../../../compounds/wnba/WnbaTeamFiltersLayout';
import withCopyableUrl, { WithCopyableUrlProps } from '../../../compounds/CopyableUrlWrapper/CopyableUrlWrapper';
import TeamGameGroupBy from '../../../compounds/TeamGameGroupBy/TeamGameGroupBy';
import FinderStackLayout from '../../../compounds/FinderStackLayout/FinderStackLayout';
import { appendContextFilters } from '../../../utils/wnba/search';

interface IProps extends WithCopyableUrlProps {};

function TeamGameFinderSearch({ handleDone }: IProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pageSpecificSearchEnabled = (_context: ContextFilterType): boolean => true;

  const statsFilterPartial = <EliasStatsFilter statsFilterOptions={statsFilters} displayYearInfo={false} />;
  const groupByPartial = <TeamGameGroupBy />
  const gameTypeModalPartial = (
    <ModalFilters
      buttonTitle='Game Type & Date Filters'
      filters={gameModalFilterCategories}
      filterType='gameTypeFilters'
    />
  );
  const advancedModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={advancedModalFilterCategories}
      filterType='advancedFilters'
      comingSoon
    />
  );

  return (
    <Dashboard>
      <FinderStackLayout
        header={<Header title={PAGE_TITLE} subtitle={PAGE_SUBTITLE} />}
        topPanel={
          <WnbaTeamFiltersLayout
            handleDone={handleDone}
            statsFilterPartial={statsFilterPartial}
            pageSpecificSearchEnabled={pageSpecificSearchEnabled}
            apiUrls={API_URLS.wnba}
            topModalPartial={gameTypeModalPartial}
            bottomModalPartial={advancedModalPartial}
            groupByPartial={groupByPartial}
          />
        }
      />
    </Dashboard>
  );
}

export default withCopyableUrl(
  TeamGameFinderSearch,
  ROUTER_URLS.wnba.teamGameFinderResults,
  statsFilters,
  appendContextFilters
);
