import React from 'react';
import { Link } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from '../../../../api/api';
import {
  PlayerStreakResult,
  PlayerStreakTableRow,
  TeamStreakResult,
  TeamStreakTableRow,
} from '../../../../api/types';
import { floatToFixed, percentToFixed } from '../../common/string';
import ROUTER_URLS from '../../router/urls';

export const generateRowNumber = (
  rowID: number,
  page: number,
  pageSize = DEFAULT_PAGE_SIZE
) => (page === 0 ? rowID + 1 : pageSize * page + rowID + 1);

export const getGameDateFromID = (gameID: number) => {
  if (!gameID) return '';
  // Converts the GameID which is in the YYYYMMMDDGG format to game date format YYYY-MM-DD
  const date = gameID.toString().slice(0, -2);
  const formattedDate = date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
  return formattedDate;
};

const generateGameFinderLink = (gameIds: number[]) => (
  <Link target="_blank" to={`${ROUTER_URLS.wnba.playerGameFinderResults.replace('/:searchId?', '')}?gameIds=${gameIds}`}>View Game-by-Game &#8594;</Link>
);

export const mapUngroupedRows = (results: PlayerStreakResult[], page: number): PlayerStreakTableRow[] =>
  results.map((row: PlayerStreakResult, index: number) => {
    const gamesLink = generateGameFinderLink(row.games);
    return {
      ...row,
      rank: generateRowNumber(index, page),
      playerId: row.player_id,
      player__player_id: row.player_id,
      first: getGameDateFromID(row.first),
      first_id: row.first,
      last: getGameDateFromID(row.last),
      last_id: row.last,
      games: row.games.length <= 5 ? row.games.map(g => getGameDateFromID(g)).join(', ') : '',
      gamesLink,

      per_game__assists: floatToFixed(row.per_game.assists, 1),
      per_game__blocks: floatToFixed(row.per_game.blocks, 1),
      per_game__defensive_rebounds: floatToFixed(row.per_game.defensive_rebounds, 1),
      per_game__disqualified: floatToFixed(row.per_game.disqualified, 1),
      per_game__double_double: floatToFixed(row.per_game.double_double, 1),
      per_game__dunks: floatToFixed(row.per_game.dunks, 1),
      per_game__field_goal_attempts: floatToFixed(row.per_game.field_goal_attempts, 1),
      per_game__field_goals_made: floatToFixed(row.per_game.field_goals_made, 1),
      per_game__free_throw_attempts: floatToFixed(row.per_game.free_throw_attempts, 1),
      per_game__free_throws_made: floatToFixed(row.per_game.free_throws_made, 1),
      per_game__minutes_display: floatToFixed(row.per_game.minutes_display, 1),
      per_game__offensive_rebounds: floatToFixed(row.per_game.offensive_rebounds, 1),
      per_game__personal_fouls: floatToFixed(row.per_game.personal_fouls, 1),
      per_game__plus_minus: floatToFixed(row.per_game.plus_minus, 1),
      per_game__points: floatToFixed(row.per_game.points, 1),
      per_game__points_in_paint: floatToFixed(row.per_game.points_in_paint, 1),
      per_game__points_off_turnovers: floatToFixed(row.per_game.points_off_turnovers, 1),
      per_game__second_chance_points: floatToFixed(row.per_game.second_chance_points, 1),
      per_game__seconds: floatToFixed(row.per_game.seconds, 1),
      per_game__started: floatToFixed(row.per_game.started, 1),
      per_game__steals: floatToFixed(row.per_game.steals, 1),
      per_game__three_point_field_goal_attempts: floatToFixed(row.per_game.three_point_field_goal_attempts, 1),
      per_game__three_point_field_goals_made: floatToFixed(row.per_game.three_point_field_goals_made, 1),
      per_game__total_rebounds: floatToFixed(row.per_game.total_rebounds, 1),
      per_game__triple_double: floatToFixed(row.per_game.triple_double, 1),
      per_game__turnovers: floatToFixed(row.per_game.turnovers, 1),

      sum__started: row.sums.started,
      sum__triple_double: row.sums.triple_double,
      sum__free_throw_percentage: percentToFixed(row.sums.free_throw_percentage, 3),
      sum__three_point_field_goal_percentage: percentToFixed(row.sums.three_point_field_goal_percentage, 3),
      sum__field_goal_percentage: percentToFixed(row.sums.field_goal_percentage, 3),
    }
  });

export const mapRows = (
  results: PlayerStreakResult[],
  groupByColumn: string,
  page: number
) => mapUngroupedRows(results as PlayerStreakResult[], page);

export const mapTeamRows = (
  results: TeamStreakResult[],
  groupByColumn: string,
  page: number,
): TeamStreakTableRow[] =>
  results.map((row: TeamStreakResult, index: number) => {
    const gamesLink = generateGameFinderLink(row.games);
    return {
      ...row,
      rank: generateRowNumber(index, page),
      franchise_id: row.franchise_id,
      franchise_name: row.franchise_name,
      first: getGameDateFromID(row.first),
      first_id: row.first,
      last: getGameDateFromID(row.last),
      last_id: row.last,
      games: row.games.length <= 5 ? row.games.map(g => getGameDateFromID(g)).join(', ') : '',
      gamesLink,

      per_game__assists: floatToFixed(row.per_game.assists, 1),
      per_game__blocks: floatToFixed(row.per_game.blocks, 1),
      per_game__defensive_rebounds: floatToFixed(row.per_game.defensive_rebounds, 1),
      per_game__field_goal_attempts: floatToFixed(row.per_game.field_goal_attempts, 1),
      per_game__field_goals_made: floatToFixed(row.per_game.field_goals_made, 1),
      per_game__free_throw_attempts: floatToFixed(row.per_game.free_throw_attempts, 1),
      per_game__free_throws_made: floatToFixed(row.per_game.free_throws_made, 1),
      per_game__offensive_rebounds: floatToFixed(row.per_game.offensive_rebounds, 1),
      per_game__personal_fouls: floatToFixed(row.per_game.personal_fouls, 1),
      per_game__points: floatToFixed(row.per_game.points, 1),
      per_game__started: floatToFixed(row.per_game.started, 1),
      per_game__steals: floatToFixed(row.per_game.steals, 1),
      per_game__three_point_field_goal_attempts: floatToFixed(row.per_game.three_point_field_goal_attempts, 1),
      per_game__three_point_field_goals_made: floatToFixed(row.per_game.three_point_field_goals_made, 1),
      per_game__total_rebounds: floatToFixed(row.per_game.total_rebounds, 1),
      per_game__turnovers: floatToFixed(row.per_game.turnovers, 1),

      sum__started: row.sums.started,
      sum__free_throw_percentage: percentToFixed(row.sums.free_throw_percentage, 3),
      sum__three_point_field_goal_percentage: percentToFixed(row.sums.three_point_field_goal_percentage, 3),
      sum__field_goal_percentage: percentToFixed(row.sums.field_goal_percentage, 3),
      sum__points_difference: row.sums.points_difference,
      sum__field_goals_difference: row.sums.field_goals_difference,
      sum__free_throws_difference: row.sums.free_throws_difference,
      sum__three_point_field_goals_difference: row.sums.three_point_field_goals_difference,
      sum__total_rebounds_difference: row.sums.total_rebounds_difference,
      sum__personal_fouls_difference: row.sums.personal_fouls_difference,
      sum__turnovers_difference: row.sums.turnovers_difference,
      sum__steals_difference: row.sums.steals_difference,
      sum__field_goal_percentage_difference: percentToFixed(row.sums.field_goal_percentage_difference, 3)
    }
  });