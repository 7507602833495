import React from 'react';
import TextField from '@mui/material/TextField';
import { InputStyles } from './StyleOverrides';
import './EliasInput.scss';
import {
  EliasInputProps,
  handleInputChange,
  handleInputFocus,
} from './elias-input-utils';

function EliasBooleanInput({
  value,
  onChange,
  id,
  ariaLabel,
  focusedOption,
  isEditing,
  placeholder = undefined,
}: EliasInputProps) {
  const handleRef = (input: any) => {
    if (input !== null) {
      if (isEditing && focusedOption === id) {
        input.focus();
      } else if (!isEditing) {
        input.blur();
      }
    }
  };

  const isInputValid = value === '' || value === '1' || value === '0';

  return (
    <TextField
      inputRef={handleRef}
      onFocus={handleInputFocus}
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
        'aria-label': ariaLabel,
        'data-testid': `${id}-value-input`,
      }}
      size="small"
      type="number"
      value={value}
      placeholder={placeholder}
      onChange={(event) => handleInputChange(event, onChange)}
      sx={InputStyles}
      error={!isInputValid}
    />
  );
}

export default EliasBooleanInput;
