import React from 'react';
import { MuiChipsInput } from 'mui-chips-input';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import StatsIcon from '@mui/icons-material/Insights';
import { EqualitySignType } from '../../utils/stats/stats-utils';
import {
  buildFilterFromTextSearch,
  StatFilter,
  createChipsDisplay
} from './filter-stats-utils';
import EliasOptionsList from './EliasOptionList';
import EliasStatsListHeader from './EliasStatsListHeader';
import EliasStatsListFooter from './EliasStatsListFooter';
import './EliasStatsFilter.scss';
import { InputContainerStyles } from './StyleOverrides';
import useKeyPress from '../../hooks/useKeyPress';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';
import { SelectedStatFilterType } from '../../types/statsFilter';

interface IProps {
  statsFilterOptions: StatFilter[];
  displayYearInfo: boolean;
}

function EliasStatsFilter({ statsFilterOptions, displayYearInfo }: IProps) {
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();

  const escapePress = useKeyPress('Escape');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const [filterTextTerm, setFilterTextTerm] = React.useState<string>('');

  const handleDone = () => {
    handleClose();
    setFilterTextTerm('');
  };

  const booleanStatsAbbrs = new Set(
    statsFilterOptions.filter((stat) => stat.type === 'boolean').map((stat) => stat.abbr)
  );

  const getDefaultSignForStatAbbr = (abbr: string) =>
    booleanStatsAbbrs.has(abbr) ? '=' : '>=';

  const findExistingChipIndex = (statFilterId: string) =>
    filterContext.selectedStatsFilters.findIndex((c: SelectedStatFilterType) => c.id === statFilterId);

  const findStatFilterOption = (statFilterId: string) =>
    statsFilterOptions.find((s: StatFilter) => s.id === statFilterId);

  const deleteChip = (index: number) => {
    const selectedStats = filterContext.selectedStatsFilters;
    selectedStats.splice(index, 1);
    const chips = createChipsDisplay(selectedStats, booleanStatsAbbrs);
    filterDispatchContext({
      type: 'selectedStatFiltersChanged',
      selectedStatsFilters: selectedStats,
      chips,
    });
  };

  const removeConflictingStats = (
    conflictingStatIds: string[],
    selectedStats: SelectedStatFilterType[]
  ): SelectedStatFilterType[] => {
    const currentSelectedStats = [...selectedStats];
    conflictingStatIds.forEach((id: string) => {
      const index = currentSelectedStats.findIndex((c: SelectedStatFilterType) => c.id === id);
      if (index > -1) {
        currentSelectedStats.splice(index, 1);
      }
    });
    return currentSelectedStats;
  };

  const addChip = ({
    id,
    abbr,
    value = '',
    equalitySymbol = '',
  }: { id: string; abbr: string; value?: string, equalitySymbol?: string }) => {
    const statFilterOption = findStatFilterOption(id);
    let selectedStats = [...filterContext.selectedStatsFilters];

    if (statFilterOption) {
      if (statFilterOption.conflictingStatIds && statFilterOption.conflictingStatIds.length > 0) {
        selectedStats = removeConflictingStats(statFilterOption.conflictingStatIds, selectedStats);
      }
      const newChip: SelectedStatFilterType = {
        id,
        abbr,
        equalitySymbol,
        value,
      };

      selectedStats = selectedStats.concat(newChip);
      const chips = createChipsDisplay(selectedStats, booleanStatsAbbrs);
      filterDispatchContext({
        type: 'selectedStatFiltersChanged',
        selectedStatsFilters: selectedStats,
        chips,
      });
    }
  };

  const updateChip = ({
    chipIndex,
    value,
    equalitySymbol,
  }: { chipIndex: number, value?: string, equalitySymbol?: string }) => {
    const selectedStats = filterContext.selectedStatsFilters;
    const chipToUpdate = selectedStats[chipIndex];
    if (equalitySymbol && equalitySymbol.length > 0) {
      chipToUpdate.equalitySymbol = equalitySymbol;
    }

    // when we update the comparison operator we dont pass a value here
    chipToUpdate.value = value ?? chipToUpdate.value;

    selectedStats.splice(chipIndex, 1, chipToUpdate);
    const chips = createChipsDisplay(selectedStats, booleanStatsAbbrs);
    filterDispatchContext({
      type: 'selectedStatFiltersChanged',
      selectedStatsFilters: selectedStats,
      chips,
    });
  };

  const resetFilters = React.useCallback(() => {
    const chips = createChipsDisplay([], booleanStatsAbbrs);
    filterDispatchContext({
      type: 'selectedStatFiltersChanged',
      selectedStatsFilters: [],
      chips,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEqualityChange = (
    sign: EqualitySignType,
    statFilterId: string,
    statFilterAbbreviation: string
  ) => {
    if (sign === null) {
      return;
    }

    const existingChipIndex = findExistingChipIndex(statFilterId);
    if (existingChipIndex !== -1) {
      updateChip({
        chipIndex: existingChipIndex,
        equalitySymbol: sign,
      });
    } else {
      addChip({
        id: statFilterId,
        abbr: statFilterAbbreviation,
        equalitySymbol: sign,
        value: '',
      });
    }
  };

  const onValueChange = React.useCallback(
    (value: string, statFilterId: string, statFilterAbbreviation: string) => {
      const existingChipIndex = findExistingChipIndex(statFilterId);
      if (existingChipIndex !== -1) {
        updateChip({
          chipIndex: existingChipIndex,
          value,
        })
        setFilterTextTerm('');
      } else {
        addChip({
          id: statFilterId,
          abbr: statFilterAbbreviation,
          equalitySymbol: getDefaultSignForStatAbbr(statFilterAbbreviation),
          value,
        });
        setFilterTextTerm('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterContext.selectedStatsFilters]
  );

  const handleChange = React.useCallback(
    (values: string[]) => {
      if (values && values.length === 0) {
        resetFilters();
        return;
      }

      if (filterTextTerm === null || filterTextTerm.length < 3) {
        return;
      }

      const statFilter = buildFilterFromTextSearch(filterTextTerm, statsFilterOptions);
      if (statFilter) {
        const existingChipIndex = findExistingChipIndex(statFilter.id);
        if (existingChipIndex !== -1) {
          updateChip({
            chipIndex: existingChipIndex,
            value: statFilter.value,
            equalitySymbol: statFilter.equalitySymbol,
          });
        } else {
          addChip({
            ...statFilter,
          });
        }
      } else {
        // eslint-disable-next-line no-console
        console.log('invalid text');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterContext.selectedStatsFilters, filterTextTerm, resetFilters]
  );

  const handleDeleteChip = (chipValue: string, chipIndex: number) => {
    deleteChip(chipIndex);
  };

  const handleFilterTextChange = (input: string) => {
    setFilterTextTerm(input.toUpperCase());
  };

  React.useEffect(() => {
    if (escapePress) handleClose();
  });

  const placeHolderText = () => {
    if (filterContext.statsFilterChips && filterContext.statsFilterChips.length > 0) return '';
    return 'Add one or more stat criteria';
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Grid className='wrapper'>
        <StatsIcon className='stats-icon'/>
        <MuiChipsInput
          className="stats-input"
          value={filterContext.statsFilterChips}
          onChange={handleChange}
          onClick={(e) => handleOpen(e)}
          inputValue={filterTextTerm.toUpperCase()}
          inputProps= {{
            id: 'elias-stats-filter',
          }}
          onInputChange={(inputValue) => handleFilterTextChange(inputValue)}
          onDeleteChip={handleDeleteChip}
          placeholder={placeHolderText()}
          fullWidth
          sx={InputContainerStyles}
          autoComplete="off"
        />
        <Popper open={open} anchorEl={anchorEl} placement="bottom-start" sx={{ zIndex: 2 }}>
          <EliasStatsListHeader displayYearInfo={displayYearInfo} />
          <EliasOptionsList
            onEqualityButtonClick={onEqualityChange}
            onValueChange={onValueChange}
            filter={filterTextTerm}
            selectedFilterChips={filterContext.selectedStatsFilters}
            statsFilterOptions={statsFilterOptions}
            displayYearInfo={displayYearInfo}
          />
          <EliasStatsListFooter onClick={handleDone} />
        </Popper>
      </Grid>
    </ClickAwayListener>
  );
}

export default EliasStatsFilter;
