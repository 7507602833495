import * as React from 'react';
import theme from '../../components/utils/theme/theme';
import { IconProps } from './types';

function CopyIcon({
  className,
  fill = theme.palette.custom.mediumGrey,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      width="20"
      viewBox="0 0 50 50"
      className={className}
    >
      <title>Copy Icon</title>
      <path
        d="M15 37.95q-1.25 0-2.125-.875T12 34.95v-28q0-1.25.875-2.125T15 3.95h22q1.25 0 2.125.875T40 6.95v28q0 1.25-.875 2.125T37 37.95Zm0-3h22v-28H15v28Zm-6 9q-1.25 0-2.125-.875T6 40.95V12.3q0-.65.425-1.075Q6.85 10.8 7.5 10.8q.65 0 1.075.425Q9 11.65 9 12.3v28.65h22.2q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075-.425.425-1.075.425Zm6-37v28-28Z"
        fill={fill}
      />
    </svg>
  );
}

export default CopyIcon;
