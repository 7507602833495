import React, { useEffect, useMemo, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { InputAdornment, styled } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CancelRounded from '@mui/icons-material/CancelRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PlayerInfo } from '../../../api/types';
import './EliasNameFilter.scss';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';
import { useFetchPlayers } from '../../hooks/useFetch';
import SearchDebounce from '../../utils/common/search';

const StyledAutocomplete = styled(Autocomplete)((props: { textcolor: string, weight: number  }) => ({
  '.MuiInputBase-root': {
    paddingLeft: '35px !important',
    '.MuiAutocomplete-input': {
      color: props.textcolor,
      fontWeight: props.weight,
    },
    '.MuiAutocomplete-endAdornment': {
      '.MuiAutocomplete-clearIndicator': {
        '.cancel-icon': {
          color: props.textcolor,
          visibility: 'visible',
        }
      }
    },
  },
}));

const GroupHeader = styled('li')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  padding: '6px 16px 6px 16px',
  color: theme.palette.custom.darkGrey,
  borderBottom: `1px solid ${theme.palette.custom.lightestGrey}`,
  backgroundColor: theme.palette.custom.white,
}));

interface IProps {
  label: string;
  actionType: string;
  actionObject: string;
  apiUrl: string;
  displayPlayerInfo: boolean;
  playerYearsText?: string;
}


function EliasNameFilter({
  label,
  actionType,
  actionObject,
  apiUrl,
  displayPlayerInfo=false,
  playerYearsText='Years',
}: IProps) {
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();

  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [options, setOptions] = useState<PlayerInfo[]>([]);

  const { data: playerData } = useFetchPlayers(searchValue, apiUrl);

  const searchDebounce: SearchDebounce = useMemo(() => new SearchDebounce(), [ ]);

  // Clean up this debounce when it is unmounted
  useEffect(() => () => searchDebounce.clear(), [ searchDebounce ]);

  const handleChange = (_event: any, newValue: PlayerInfo | null) => {
    setOptions(newValue ? [newValue, ...options] : options);

    filterDispatchContext({
      type: actionType,
      [actionObject]: newValue,
      searchEnabled: true,
    });
  };

  const handleInputChange = (_event: any, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue !== inputValue) {
      searchDebounce.debounce(() => setSearchValue(newInputValue));
    }
  };
  const textColor = filterContext[actionObject] &&
    filterContext[actionObject].id ? '#175CD3' : '#101828';

  const fontweight = filterContext[actionObject] &&
    filterContext[actionObject].id ? 500 : 400;

  const formatOptionSeasons = (option: any) => {
    const defaultReturn = ['-'];
    if (typeof(option) === undefined || option === null) {
      return defaultReturn;
    }

    if (option.is_freshman) {
      return <span style={{ fontWeight: 600 }}>{option.last_year}</span>
    }

    // only multi-year players
    let value = `${option.first_year}`;
    if (option.last_year) {
      value = `${value}-${option.last_year}`;
    }

    if (option.is_active) {
      return <span style={{ fontWeight: 600 }}>{value}</span>
    }
    return value;
  }

  return (
    <Box>
      <StyledAutocomplete
        id="elias-name-filter"
        data-testid="elias-name-filter"
        options={playerData?.results ?? []}
        value={filterContext[actionObject]}
        inputValue={inputValue}
        includeInputInList
        filterOptions={(x) => x}
        isOptionEqualToValue={(option: any) =>
          option.id === filterContext[actionObject]?.id
        }
        noOptionsText="No matches found."
        getOptionLabel={(option: any) => option?.display_name ?? ''}
        onChange={(event: any, value: any) => handleChange(event, value)}
        onInputChange={handleInputChange}
        ListboxProps={{ sx: {paddingTop: '0px'}}}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        groupBy={(option) => ''}
        renderGroup={( params ) => {
          const { children } = params;
          const newChildren = [...children];
          if (displayPlayerInfo && newChildren) {
            newChildren.unshift(
              <GroupHeader key='group-header'>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography component="span" fontWeight="600">
                      Player
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography component="span" fontWeight="600">
                      { playerYearsText }
                    </Typography>
                  </Grid>
                </Grid>
              </GroupHeader>
            );
          }
          return newChildren;
        }}
        renderInput={(params: any) => (
          <TextField {...params} placeholder={label} InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon className="person-icon" />
              </InputAdornment>
            ),
          }} />
        )}
        renderOption={(props, option: any) => (
          <li {...props} key={option.id}>
            <Grid container>
              <Grid item xs={8}>
                <Typography component="span" fontWeight="400" color='#1010828'>
                  {option?.display_name ?? ''}
                </Typography>
              </Grid>
              { displayPlayerInfo &&
                <Grid item xs={4}>
                  {formatOptionSeasons(option)}
                </Grid>
              }
            </Grid>
          </li>
        )}
        popupIcon={
          <ExpandMoreIcon />
        }
        forcePopupIcon={!filterContext[actionObject]?.id}
        clearIcon={
          <CancelRounded className='cancel-icon' />
        }
        textcolor={textColor}
        weight={fontweight}
      />
    </Box>
  );
}

export default EliasNameFilter;
