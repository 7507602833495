export function range(start: number, end: number): number[] {
    const startLocal = Math.floor(start);
    const endLocal = Math.floor(end);

    const diff = endLocal - startLocal;
    if (diff === 0) {
        return [startLocal];
    }

    const keys = Array(Math.abs(diff) + 1).keys();
    return Array.from(keys).map(x => {
        const increment = endLocal > startLocal ? x : -x;
        return startLocal + increment;
    });
}

export default range;