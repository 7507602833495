import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import EllipsisLoading from '../Common/EllipsisLoading';
import ErrorMessage from '../../atoms/ErrorMessage/ErrorMessage';

interface IProps {
  error?: Error | null,
  loadingMessage?: string,
}

function ResultsLoading({ error, loadingMessage }: IProps) {
  return (
    <Grid>
      <Grid item xs={12} >
        { error && error.message.length > 0 ?
          <ErrorMessage error={error} /> :
          (
            <Box textAlign='center'>
              <h3>{ loadingMessage }</h3>
              <EllipsisLoading />
            </Box>
          )
        }
      </Grid>
    </Grid>
  );
}

export default ResultsLoading;
