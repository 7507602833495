import theme from '../../utils/theme/theme';

export const buttonGroupStyles = {
  height: 36,
  marginRight: 1,
  borderColor: theme.palette.custom.lightMediumGrey,
  '.MuiToggleButton-root.Mui-selected': {
    backgroundColor: theme.palette.custom.mediumBlueGrey,
    borderColor: theme.palette.custom.lightMediumGrey,
  },
  backgroundColor: '#fff'
};

export const booleanButtonGroupStyles = {
  height: 36,
  marginRight: 1,
  borderColor: theme.palette.custom.lightMediumGrey,
  '.MuiToggleButton-root.Mui-selected': {
    backgroundColor: theme.palette.custom.mediumBlueGrey,
    borderColor: theme.palette.custom.lightMediumGrey,
  },
  backgroundColor: '#fff'
};

export const buttonStyles = {
  color: theme.palette.custom.darkGrey,
};
