import { DEBOUNCE_DELAY } from "./constants";

export default class SearchDebounce {
    private debounceTimer: number = 0;

    private debounceDelay = DEBOUNCE_DELAY;

    
    constructor(debounceDelay: number = DEBOUNCE_DELAY) {
        this.debounceDelay = debounceDelay;
    }
    
    public debounce = (callback: Function) => {
        clearTimeout(this.debounceTimer);

        this.debounceTimer = setTimeout(callback, this.debounceDelay);
    }

    public clear = () => {
        clearTimeout(this.debounceTimer);
    }
}
