import React from 'react';
import ROUTER_URLS from '../../../utils/router/urls';
import Header from '../../../compounds/Common/Header';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import { statsFilters, PAGE_TITLE, PAGE_SUBTITLE } from './constants';
import PlayerGameGroupBy from '../../../compounds/PlayerGameGroupBy/PlayerGameGroupBy';
import API_URLS from '../../../../api/api_urls';
import { advancedModalFilterCategories, gameModalFilterCategories } from '../constants';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import { ContextFilterType } from '../../../types/context';
import WnbaPlayerFiltersLayout from '../../../compounds/wnba/WnbaPlayerFiltersLayout';
import withCopyableUrl, { WithCopyableUrlProps } from '../../../compounds/CopyableUrlWrapper/CopyableUrlWrapper';
import FinderStackLayout from '../../../compounds/FinderStackLayout/FinderStackLayout';
import { appendContextFilters } from '../../../utils/wnba/search';

interface IProps extends WithCopyableUrlProps {};

function PlayerGameFinderSearch({ handleDone }: IProps) {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pageSpecificSearchEnabled = (_context: ContextFilterType): boolean => true;

  const statsFilterPartial = <EliasStatsFilter statsFilterOptions={statsFilters} displayYearInfo={false} />;

  const groupByPartial = <PlayerGameGroupBy />

  const topModalPartial = (
    <ModalFilters
      buttonTitle='Game Type & Date Filters'
      filters={gameModalFilterCategories}
      filterType='gameTypeFilters'
    />
  );

  const bottomModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={advancedModalFilterCategories}
      filterType='advancedFilters'
    />
  );

  return (
    <Dashboard>
      <FinderStackLayout
        header={<Header title={PAGE_TITLE} subtitle={PAGE_SUBTITLE} />}
        topPanel={
          <WnbaPlayerFiltersLayout
            handleDone={handleDone}
            statsFilterPartial={statsFilterPartial}
            pageSpecificSearchEnabled={pageSpecificSearchEnabled}
            groupByPartial={groupByPartial}
            apiUrls={API_URLS.wnba}
            topModalPartial={topModalPartial}
            bottomModalPartial={bottomModalPartial}
            showHalfQuarter
          />
        }
      />
    </Dashboard>
  );
}

export default withCopyableUrl(
  PlayerGameFinderSearch,
  ROUTER_URLS.wnba.playerGameFinderResults,
  statsFilters,
  appendContextFilters,
);
