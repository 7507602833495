import * as React from 'react';
import { generatePath, useNavigate } from 'react-router';
import { Button, MenuItem, MenuList, Paper, Stack, styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuthUser, useSignOut } from 'react-auth-kit'
import { doLogout } from '../../../api/auth';
import { SearchNavUserStyles } from './StyleOverrides';
import ROUTER_URLS from '../../utils/router/urls';

const PopoverListItem = styled(Stack)(() => ({
  position: 'relative',
  "&:hover .MuiPaper-root": {
    display: 'block'
  }
}))

const UserAccountPopover = styled(Paper)(() => ({
  position: 'absolute',
  zIndex:2,
  right: 0,
  top: 30,
  width: 170,
  display: 'none'
}))

function UserPopover() {
  const auth = useAuthUser();
  const user = auth();
  const signOut = useSignOut();
  const navigate = useNavigate();

  const navigateToChangePassword = () => {
    const path = generatePath(ROUTER_URLS.changePassword);
    navigate(path);
  };

  const logout = () => {
    doLogout();
    
    signOut();
  };

  return (
    <div>
      <PopoverListItem sx={SearchNavUserStyles}>
        <Button sx={{ color: '#fff', textTransform: 'none', fontSize: '16px' }} endIcon={<KeyboardArrowDownIcon />}>
          Welcome {user?.first_name}
        </Button>
        <UserAccountPopover elevation={4}>
          <MenuList>
            <MenuItem onClick={navigateToChangePassword}>Change password</MenuItem>
            <MenuItem onClick={logout}>Log out</MenuItem>
          </MenuList>
        </UserAccountPopover>
      </PopoverListItem>
    </div>
  );
}

export default UserPopover;