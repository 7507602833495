import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import './EliasOptionList.scss';

interface EliasFooterProps {
  onClick: () => void;
}

function EliasStatsListFooter({ onClick }: EliasFooterProps) {
  return (
    <Box className="list-footer">
      <Button variant="contained" onClick={onClick}>
        Done
      </Button>
    </Box>
  );
}

export default EliasStatsListFooter;
