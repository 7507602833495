import theme from '../../utils/theme/theme';

export const OptionsListItemStyles = {
  height: 44,
  backgroundColor: theme.palette.custom.white,
  color: theme.palette.custom.mediumDarkGrey,
  '&:focus-visible': {
    backgroundColor: theme.palette.custom.lightGrey,
    outline: 'none !important',
  },
};

export const OptionListContainerStyles = {
  width: 731,
  overflowY: 'scroll',
  border: `1px solid ${theme.palette.custom.lightestGrey} !important`,
  paddingTop: 0,
  '@media (max-height: 40em)': {
    maxHeight: '20vh',
  },
  '@media (min-height: 40em) and (max-height: 50em)': {
    maxHeight: '30vh',
  },
  '@media (min-height: 50em)': {
    maxHeight: '40vh',
  },
};

export const InputContainerStyles = {
  minWidth: 185,
  '.MuiInputBase-root': {
    paddingLeft: '40px !important',
  },
  '.MuiChip-root': {
    color: '#175CD3',
    backgroundColor: '#EFF8FF',
    '& .MuiChip-deleteIcon': {
      color: '#175CD3',
      fontSize: '1.25em',
    },
  },
  display: 'block',
};

export const EqualityRowContainerStyles = {
  display: 'grid',
  gridTemplateColumns: '250px 398px 1fr',
};
