import React from 'react';
import ROUTER_URLS from '../../../utils/router/urls';
import Header from '../../../compounds/Common/Header';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import PlayerSpanFilters from '../../../compounds/PlayerSpanFilters/PlayerSpanFilters';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import { statsFilters, spanRangeOptions, PAGE_TITLE, PAGE_SUBTITLE } from './constants';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import { advancedModalFilterCategories, gameModalFilterCategories } from '../constants';
import { ContextFilterType } from '../../../types/context';
import NbaPlayerFiltersLayout from '../../../compounds/nba/NbaPlayerFiltersLayout';
import FinderStackLayout from '../../../compounds/FinderStackLayout/FinderStackLayout';
import withCopyableUrl, { WithCopyableUrlProps } from '../../../compounds/CopyableUrlWrapper/CopyableUrlWrapper';
import { appendContextFilters } from '../../../utils/nba/search';

interface IProps extends WithCopyableUrlProps {};

function PlayerSpanFinderSearch({ handleDone }: IProps) {

  const pageSpecificSearchEnabled = (context: ContextFilterType): boolean =>
    Boolean(context.spanLength) && Number(context.spanLength) > 1;

  const statsFilterPartial = <EliasStatsFilter statsFilterOptions={statsFilters} displayYearInfo />;

  const topModalPartial = (
    <ModalFilters
      buttonTitle='Game Type & Date Filters'
      filters={gameModalFilterCategories}
      filterType='gameTypeFilters'
    />
  );

  const bottomModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={advancedModalFilterCategories}
      filterType='advancedFilters'
    />
  );

  return (
    <Dashboard>
      <FinderStackLayout
        header={<Header title={PAGE_TITLE} subtitle={PAGE_SUBTITLE} />}
        topPanel={
          <NbaPlayerFiltersLayout
            handleDone={handleDone}
            statsFilterPartial={statsFilterPartial}
            pageSpecificSearchEnabled={pageSpecificSearchEnabled}
            apiUrls={API_URLS.nba}
            topModalPartial={topModalPartial}
            bottomModalPartial={bottomModalPartial}
          >
            <PlayerSpanFilters spanRangeOptions={spanRangeOptions} />
          </NbaPlayerFiltersLayout>
        }
      />
    </Dashboard>
  );
}

export default withCopyableUrl(
  PlayerSpanFinderSearch,
  ROUTER_URLS.nba.playerSpanFinderResults,
  statsFilters,
  appendContextFilters,
);
