export type FinderSelectOptionType = {
  id: string;
  label: string;
};

export const gameTypeStatusOptions: FinderSelectOptionType[] =[
  { id: 'any', label: 'Any'},
  { id: 'regular-season', label: 'Regular Season'},
  { id: 'conference', label: 'Conference'},
  { id: 'non-conference', label: 'Non-Conference'},
  { id: 'conference-tournament', label: 'Conference Tournament'},
  { id: 'ncaa-tournament', label: 'NCAA Tournament'},
];

export const rankingOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any'},
  { id: 'number-one', label: '#1' },
  { id: 'top-5', label: 'Top 5' },
  { id: 'top-10', label: 'Top 10' },
  { id: 'ranked', label: 'Ranked' },
  { id: 'unranked', label: 'Unranked' },
];

export const activeStatusOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'active', label: 'Active Players' },
  { id: 'not-active', label: 'Not Active' },
];

export const rookieStatusOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'rookie', label: 'Rookie' },
  { id: 'not-rookie', label: 'Not Rookie' },
];

export const homeAwayOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'away', label: 'Away' },
  { id: 'home', label: 'Home' },
  { id: 'neutral', label: 'Neutral' }
];

export const overtimeStatusOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Any' },
  { id: 'overtime', label: 'OT Only' },
  { id: 'no-overtime', label: 'No OT' },
];

export const groupByOptions: FinderSelectOptionType[] = [
  { id: 'none', label: 'None' },
  { id: 'player', label: 'Player career instances' },
  { id: 'player-season', label: 'Player within a season' },
  { id: 'player-franchise', label: 'Player with a franchise' },
  { id: 'player-opponent', label: 'Player against an opponent' },
  // { id: 'player-franchise-season', label: 'Player with a franchise in a season' },
  // { id: 'player-opponent-season', label: 'Player against an opponent in a season' },
];

export const quarterHalfStatusOptions: FinderSelectOptionType[] = [
  { id: 'none', label: 'None' },
  { id: 'H', label: 'Any Half' },
  { id: 'Q', label: 'Any Quarter' },
  { id: 'H1', label: 'H1' },
  { id: 'H2', label: 'H2' },
  { id: 'Q1', label: 'Q1' },
  { id: 'Q2', label: 'Q2' },
  { id: 'Q3', label: 'Q3' },
  { id: 'Q4', label: 'Q4' },
  { id: 'OT', label: 'OT (combined)' },
];

export const DEFAULT_ACTIVE_STATUS = activeStatusOptions[0].id;
export const DEFAULT_GAMETYPE_STATUS = gameTypeStatusOptions[0].id;
export const DEFAULT_GROUP_BY_STATUS = groupByOptions[0].id;
export const DEFAULT_HOME_AWAY = homeAwayOptions[0].id;
export const DEFAULT_OVERTIME_STATUS = overtimeStatusOptions[0].id;
export const DEFAULT_QUARTER_HALF_STATUS = quarterHalfStatusOptions[0].id;
export const DEFAULT_RANKING_STATUS = rankingOptions[0].id;
export const DEFAULT_ROOKIE_STATUS = rookieStatusOptions[0].id;
