import React from 'react';
import { generatePath } from 'react-router-dom';
import { TableCell, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import StyledTable from '../../atoms/StyledTable/StyledTable';
import { FinderSelectOptionType } from '../FastFilters/constants';
import FinderSelect from '../../atoms/FinderSelect/FinderSelect';
import TournamentFactCard from './TournamentFactCard';
import FactCard from '../../atoms/FactCard/FactCard';
import API_URLS from '../../../api/api_urls';
import { useFetchFranchiseSeason } from '../../hooks/useFetch';
import { FranchisePlayerSeasonStats, FranchiseSeasonType, FranchiseTeamGameType, SeasonProfileTournamentType, TournamentSummaryDataType } from '../../types/franchise';
import { formatDate } from '../../utils/common/date';
import ROUTER_URLS from '../../utils/router/urls';
import { formatSeasonDisplay } from '../../utils/common/results';
import { range } from '../../utils/common/number';
import { CURRENT_SEASON_YEAR } from '../../utils/ncaambb/constants';
import { percentToFixed } from '../../utils/common/string';

const playerStatsHeaders = [
  'Player',
  '#',
  // 'Height',
  // 'Weight',
  // 'Class',
  'GP',
  'PTS',
  'REB',
  'AST',
  'BLK',
  'STL',
  'FG%',
  '3PT%',
  'FT%',
  'PPG',
  'RPG',
  'APG',
  'SPG',
  'BPG',
];

const playerStatsMap = {
  'Player': 'nameLink',
  '#': 'number',
  'Height': 'height',
  'Weight': 'weight',
  'Class': 'year',
  'GP': 'gamesPlayed',
  'PTS': 'points',
  'REB': 'rebounds',
  'AST': 'assists',
  'BLK': 'blocks',
  'STL': 'steals',
  'FG%': 'fieldGoalPct',
  '3PT%': 'threePointFieldGoalPct',
  'FT%': 'freeThrowPct',
  'PPG': 'pointsPerGame',
  'RPG': 'reboundsPerGame',
  'APG': 'assistsPerGame',
  'SPG': 'blocksPerGame',
  'BPG': 'stealsPerGame',
};

const scheduleStatsHeaders = [
  // 'Game',
  'Date',
  'At',
  'Opp',
  'Game Type',
  'Result',
  'STL',
  'BLK',
  'TO',
  // 'PF',
  'OREB',
  'DREB',
  'REB',
  'AST',
];

const scheduleStatsMap = {
  'Game': 'row',
  'Date': 'gameLink',
  'At': 'homeAway',
  'Opp': 'opponentName',
  'Game Type': 'gameType',
  'Result': 'result',
  'STL': 'steals',
  'BLK': 'blocks',
  'TO': 'turnovers',
  'PF': 'personalFouls',
  'OREB': 'offensiveRebounds',
  'DREB': 'defensiveRebounds',
  'REB': 'rebounds',
  'AST': 'assists',
};

const seasonSelectOptions: FinderSelectOptionType[] = [
  { id: 'any', label: 'Choose Season' },
].concat(range(CURRENT_SEASON_YEAR, 2021).map(
  (season) => ({ id: season.toString(), label: formatSeasonDisplay(season) })
  )
);

interface TournamentGame {
  id: number;
  round: string;
  result: string;
  teamScore: number;
  opponentScore: number;
  opponentName: string;
};

const mapTournament = (
  teamGames: FranchiseTeamGameType[],
  info?: SeasonProfileTournamentType
): TournamentSummaryDataType | undefined => {
  if (!info) return undefined;

  const games = info.games.map((gameId: number) => {
    const teamGame = teamGames.find((game) => game.game_id === gameId);
    if (!teamGame) return undefined;
    return {
      id: gameId,
      round: teamGame.round || '',
      result: teamGame.result.result,
      teamScore: teamGame.result.score,
      opponentScore: teamGame.result.opponent_score,
      opponentName: teamGame.opponent.short_name,
    }
  });
  const cleanedGames = games.filter((g): g is TournamentGame => g !== undefined);
  return {
    seed: info.seed,
    region: info.region,
    games: cleanedGames,
  };
};

const mapPlayerStats = (data: FranchisePlayerSeasonStats[]) =>
  data.map(row => {
    const playerLink = (
      <a href={generatePath(ROUTER_URLS.ncaambb.player, { playerId: row.player_id })}>{row.name}</a>
    );
    return {
      id: row.player_id,
      name: row.name,
      nameLink: playerLink,
      number: row.uniform_number,
      height: row.height,
      weight: row.weight,
      year: row.class,
      gamesPlayed: row.regular_season.games,
      points: row.regular_season.points,
      rebounds: row.regular_season.total_rebounds,
      assists: row.regular_season.assists,
      blocks: row.regular_season.blocks,
      steals: row.regular_season.steals,
      fieldGoalPct: percentToFixed(row.regular_season.field_goal_percentage, 3),
      threePointFieldGoalPct: percentToFixed(row.regular_season.three_point_field_goal_percentage, 3),
      freeThrowPct: percentToFixed(row.regular_season.free_throw_percentage, 3),
      pointsPerGame: row.per_game.points,
      reboundsPerGame: row.per_game.total_rebounds,
      assistsPerGame: row.per_game.assists,
      blocksPerGame: row.per_game.blocks,
      stealsPerGame: row.per_game.steals,
    }
  });

const mapTeamGames = (data: FranchiseTeamGameType[]) =>
  data.map(row => {
    const formattedGameDate = formatDate(row.date, "yyyy-MM-dd");
    const gameLink = (
      <a href={generatePath(ROUTER_URLS.ncaambb.game, { gameId: row.game_id })}>{formattedGameDate}</a>
    );
    return {
      id: row.game_id,
      gameLink,
      date: formattedGameDate,
      homeAway: row.home_road,
      opponentName: row.opponent.short_name,
      gameType: row.game_type,
      result: `${row.result.result.toUpperCase()} ${row.result.score}-${row.result.opponent_score}`,
      steals: row.stats.steals,
      blocks: row.stats.blocks,
      turnovers: row.stats.turnovers,
      personalFouls: row.stats.personal_fouls,
      rebounds: row.stats.total_rebounds,
      offensiveRebounds: row.stats.offensive_rebounds,
      defensiveRebounds: row.stats.defensive_rebounds,
      assists: row.stats.assists,
    }
  });

interface IProps {
  teamId: string;
}

function TeamSeasonsTab({ teamId }: IProps) {
  const [selectedSeason, setSelectedSeason] = React.useState(CURRENT_SEASON_YEAR.toString());

  const apiUrl = API_URLS.ncaambb;

  const { data: franchiseDetailData, isLoading, error } = useFetchFranchiseSeason(teamId, selectedSeason, apiUrl.franchise_season_profile);

  const handleSeasonChange = (selection: string) => {
    setSelectedSeason(selection);
  };

  if (isLoading) {
    return <span>Loading...</span>
  }

  if (error && error instanceof Error) {
    return <span>Error: {error.message}</span>
  }

  const franchiseSeasonData: FranchiseSeasonType | undefined = franchiseDetailData && franchiseDetailData.results[0];
  if (!franchiseSeasonData) {
    return <span>Error: Data failed to load</span>
  }

  const seasonProfile = franchiseSeasonData.season_profile;
  const teamGames = franchiseSeasonData.team_games;
  const playerSeasonStats = franchiseSeasonData.player_season_stats;

  const ncaaTournamentData = mapTournament(teamGames, seasonProfile.tournaments?.ncaa);
  const conferenceTournamentData = mapTournament(teamGames, seasonProfile.tournaments?.conference);
  const mappedPlayerStats = mapPlayerStats(playerSeasonStats);
  const mappedTeamGames = mapTeamGames(teamGames);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <FinderSelect
            label='Season'
            value={selectedSeason}
            options={seasonSelectOptions}
            handleSelection={handleSeasonChange}
            disabled
          />
        </Grid>
        <Grid item>
          <Typography component="h1" variant="h6" fontWeight="600">{seasonProfile.commone_name} Men&apos;s Basketball History</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={2} pl={2}>
          <FactCard label='Overall Record' values={[`${seasonProfile.record.overall.wins} - ${seasonProfile.record.overall.losses}`]} />
          <FactCard label='Conference Record' values={[`${seasonProfile.record.conference.wins} - ${seasonProfile.record.conference.losses}`]} />
          <FactCard label='Last AP Rank' values={[`${seasonProfile.ranking ? seasonProfile.ranking.final : ''}`]} />
        </Grid>
        <Grid item xs={5}>
          { ncaaTournamentData && <TournamentFactCard label='NCAA Tournament' data={ncaaTournamentData} /> }
          { conferenceTournamentData && <TournamentFactCard label='Conference Tournament' data={conferenceTournamentData} /> }
        </Grid>
      </Grid>
      <Grid container mt={5}>
        {
          // @todo: this is weird that the tables are spilling over the right edge of the page but theres not time to look into it.
          // adding margin for now and will need to investigate at a later date
        }
        <Grid item xs={12} marginRight={5}>
          <StyledTable
            uniqueRowKey='id'
            headers={playerStatsHeaders}
            data={mappedPlayerStats}
            dataMap={playerStatsMap}
            labelHeaderRow={
              <TableRow>
                <TableCell colSpan={10}><h5 style={{ fontSize: '1.4em', margin: 0, }}>Player Stats</h5></TableCell>
                <TableCell colSpan={6} align='right'>
                  <span style={{ fontSize: '1.25em', margin: 0, fontWeight: 600, marginRight: 20, }}>Head Coach</span>
                  <span style={{ fontSize: '1.15em', margin: 0, }}>{seasonProfile.head_coach}</span>
                </TableCell>
              </TableRow>
            }
          />
        </Grid>
      </Grid>

      <Grid container mt={5}>
        <Grid item xs={12} marginRight={5}>
          <StyledTable uniqueRowKey='id' headers={scheduleStatsHeaders} data={mappedTeamGames} dataMap={scheduleStatsMap} />
        </Grid>
      </Grid>
    </>
  );
}

export default TeamSeasonsTab;
