import { SelectedStatFilterType } from '../../types/statsFilter';
import { EqualitySigns } from '../../utils/stats/stats-utils';

export type StatFilter = {
  id: string;
  abbr: string;
  name: string;
  year?: number;
  type: FilterType;
  explanation?: string;
  searchModifier?: (
    value: string,
    modifierValue: number,
  ) => string;
  conflictingStatIds?: string[];
};

export enum StatFilterType {
  'basic' = 'basic',
  'equality' = 'equality',
  'boolean' = 'boolean',
}

export type FilterType = `${StatFilterType}`;

const findEqualitySymbolFromText = (text: string): string => {
  const matchedSymbol = text.match(/[<>=]=|=/);
  return matchedSymbol ? matchedSymbol[0] : EqualitySigns['>='];
};

const findStatValueFromText = (text: string) =>
  (text.match(/[-.0-9]+$/) ?? ['0'])[0];

// the stat filters are currently displayed in the same order they are listed in the array above.
// however we have multiple filters with the similar abbreviations. use reverse on the array to
// check for longer abbreviations before shorter ones
const findFilterFromText = (text: string, statsFilterOptions: StatFilter[]) => {
  const matchedFilter = [...statsFilterOptions]
    .reverse()
    .find(
      (filter) => {
        if (filter.name === '') {
          // eslint-disable-next-line
          console.log('ERROR - You need to add a non-empty name value for all filters or the power-user function will break');
        };
        return text.includes(filter.abbr) || text.toLowerCase().includes(filter.name.toLowerCase());
      }
    );
  return matchedFilter;
};

export const buildFilterFromTextSearch = (
  filterText: string,
  statsFilterOptions: StatFilter[],
): SelectedStatFilterType | null => {
  const statFilter = findFilterFromText(filterText, statsFilterOptions);
  if (statFilter) {
    const equalitySymbol = findEqualitySymbolFromText(filterText);
    const value = findStatValueFromText(filterText);
    return { id: statFilter.id, abbr: statFilter.abbr, value, equalitySymbol };
  }
  // @todo: do we need to return something different here?
  return null;
};

export const createChipsDisplay = (
  pickedChips: SelectedStatFilterType[],
  booleanStatsAbbrs: Set<string>,
): string[] =>
  pickedChips
    .filter((chip) => chip.value && chip.value.length > 0)
    .map((chip) => {
      if (booleanStatsAbbrs.has(chip.abbr)) {
        return `${chip.abbr}${EqualitySigns['=']}${chip.value}`;
      }
      if (chip.equalitySymbol || chip.value) {
        return `${chip.abbr}${chip.equalitySymbol}${chip.value}`;
      }
      return '';
    })
    .filter((chip) => chip !== '');
