import { ColumnMappingType } from '../../../types/results';
import { SelectedStatFilterType } from '../../../types/statsFilter';
import { showHideColumns } from '../../common/results';
import { PER_GAME_RESULT_COLUMNS, RESULT_COLUMNS, SUM_GAME_RESULT_COLUMNS } from '../../common/map-columns';

const DEFAULT_COL_WIDTH = 80;

export const ungroupedColumnMapping: ColumnMappingType[] = [
  {
    headerName: 'Row',
    field: 'rank',
    width: 40,
    sortable: false,
    display: 'alwaysFirst',
    align: 'center',
    headerAlign: 'center',
  },
  RESULT_COLUMNS.playerDisplayName,
  {
    headerName: 'Span',
    field: 'span',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  RESULT_COLUMNS.firstOccurence,
  RESULT_COLUMNS.lastOccurence,
  // RESULT_COLUMNS.startAge,
  // RESULT_COLUMNS.endAge,
  SUM_GAME_RESULT_COLUMNS.sumTeamWin,
  SUM_GAME_RESULT_COLUMNS.sumTeamLoss,
  // {
  //   headerName: 'GS',
  //   field: 'sum__started',
  //   width: DEFAULT_COL_WIDTH,
  //   display: 'alwaysThird',
  // },
  PER_GAME_RESULT_COLUMNS.points,
  PER_GAME_RESULT_COLUMNS.totalRebounds,
  PER_GAME_RESULT_COLUMNS.assists,
  PER_GAME_RESULT_COLUMNS.blocks,
  PER_GAME_RESULT_COLUMNS.steals,

  SUM_GAME_RESULT_COLUMNS.fieldGoalPercentage,
  SUM_GAME_RESULT_COLUMNS.threePointFieldGoalPercentage,
  SUM_GAME_RESULT_COLUMNS.freeThrowPercentage,
  SUM_GAME_RESULT_COLUMNS.tripleDouble,

  RESULT_COLUMNS.gameDatesArray,

  SUM_GAME_RESULT_COLUMNS.assistsDifference,
  SUM_GAME_RESULT_COLUMNS.blocksDifference,
  SUM_GAME_RESULT_COLUMNS.fieldGoalsDifference,
  SUM_GAME_RESULT_COLUMNS.fieldGoalPercentageDifference,
  SUM_GAME_RESULT_COLUMNS.freeThrowsDifference,
  SUM_GAME_RESULT_COLUMNS.jumpBallsWonDifference,
  SUM_GAME_RESULT_COLUMNS.personalFoulsDifference,
  SUM_GAME_RESULT_COLUMNS.pointsDifference,
  SUM_GAME_RESULT_COLUMNS.stealsDifference,
  SUM_GAME_RESULT_COLUMNS.threePointFieldGoalsDifference,
  SUM_GAME_RESULT_COLUMNS.totalReboundsDifference,
  SUM_GAME_RESULT_COLUMNS.turnoversDifference,
  SUM_GAME_RESULT_COLUMNS.winPercentageDifference,

  SUM_GAME_RESULT_COLUMNS.franchiseScore,
  SUM_GAME_RESULT_COLUMNS.opponentScore,
  SUM_GAME_RESULT_COLUMNS.personalFouls,

  PER_GAME_RESULT_COLUMNS.fieldGoalsMade,
  PER_GAME_RESULT_COLUMNS.fieldGoalAttempts,
  PER_GAME_RESULT_COLUMNS.threePointFieldGoalsMade,
  PER_GAME_RESULT_COLUMNS.threePointFieldGoalAttempts,
  PER_GAME_RESULT_COLUMNS.freeThrowsMade,
  PER_GAME_RESULT_COLUMNS.freeThrowAttempts,
  PER_GAME_RESULT_COLUMNS.offensiveRebounds,
  PER_GAME_RESULT_COLUMNS.defensiveRebounds,
  PER_GAME_RESULT_COLUMNS.pointsInPaint,
  PER_GAME_RESULT_COLUMNS.pointsOffTurnovers,
  PER_GAME_RESULT_COLUMNS.dunks,
  PER_GAME_RESULT_COLUMNS.secondChancePoints,
  PER_GAME_RESULT_COLUMNS.doubleDouble,
  PER_GAME_RESULT_COLUMNS.tripleDouble,
  PER_GAME_RESULT_COLUMNS.turnovers,
  PER_GAME_RESULT_COLUMNS.plusMinus,
  PER_GAME_RESULT_COLUMNS.franchiseScore,
  PER_GAME_RESULT_COLUMNS.opponentScore,
  PER_GAME_RESULT_COLUMNS.personalFouls,
];

export const ungroupedTeamColumnMapping: ColumnMappingType[] = ungroupedColumnMapping.map(col => {
  const { field } = col;

  // fields to remove
  if ([
    'start_age',
    'end_age',
    // 'sum__triple_double',
    // 'per_game__double_double',
    // 'per_game__dunks',
    'per_game__plus_minus',
    // 'per_game__points_in_paint',
    // 'per_game__points_off_turnovers',
    // 'per_game__second_chance_points',
    // 'per_game__triple_double',
    // 'per_game__turnovers',
  ].includes(field)) {
    return {
      ...col,
      field: 'remove',
    }
  }

  // fields to modify
  if (field === 'player__display_name') {
    return {
      ...col,
      field: 'franchise_name',
      headerName: 'Team',
    };
  }

  return col;
}).filter(col => col.field !== 'remove');

export const mapColumns = (groupByColumn: string, localFilters: SelectedStatFilterType[]) =>
  showHideColumns(ungroupedColumnMapping, localFilters);

export const mapTeamColumns = (
  groupByColumn: string,
  localFilters: SelectedStatFilterType[]
) => showHideColumns(ungroupedTeamColumnMapping, localFilters);
