import React from 'react';
import { GridCellParams, GridColumnHeaderParams } from '@mui/x-data-grid';
import { ExternalLeagueRoutesType } from '../../utils/router/external_urls';

// TODO: punting on selectively linking game IDs/dates for the time being
// const FIRST_AE2_GAME = 1992110600;

export const generateCellClass = (sortColumn: string) =>
  function generateClass(
    params: GridCellParams<string> | GridColumnHeaderParams<string>
  ) {
    return sortColumn === params.field
      ? 'data-grid-active-cell'
      : 'data-grid-inactive-cell';
  };

const generatePlayerProfileRoute = (
  params: GridCellParams<string>,
  valueFieldName: string,
  externalUrls: ExternalLeagueRoutesType,
) => {
  const playerId = params.row[valueFieldName];
  return playerId ? externalUrls.playerProfile.replace(':playerId', playerId) : '';
};

const generateFranchiseProfileRoute = (
  params: GridCellParams<string>,
  column: string,
  externalUrls: ExternalLeagueRoutesType,
) => {
  const { row } = params;

  const opponentLinkFields = ['opponent_season__abbr_3', 'opponent_name'];
  const franchiseId = opponentLinkFields.includes(column) ? row.opponent_id : row.franchise_id;
  return franchiseId ? externalUrls.franchiseProfile.replace(':teamId', franchiseId) : '';
};

  // @TODO: punting on selectively linking game IDs/dates for the time being
  // if (gameId < FIRST_AE2_GAME) {
  //   return '';
  // }
const generateBoxscoreRoute = (
  params: GridCellParams<string>,
  valueFieldName: string,
  externalUrls: ExternalLeagueRoutesType,
) => {
  const gameId = params.row[valueFieldName];
  return gameId ? externalUrls.boxscore.replace(':gameId', gameId) : '';
};

const generateLinkRoute = (
  column: string,
  params: GridCellParams<string>,
  externalUrls: ExternalLeagueRoutesType,
) => {
  switch (column) {
    case 'player__display_name':
      return generatePlayerProfileRoute(params, 'player__player_id', externalUrls);
    case 'display_name':
      return generatePlayerProfileRoute(params, 'player_id', externalUrls);
    case 'franchise_display_name':
      return generateFranchiseProfileRoute(params, column, externalUrls);
    case 'franchise_name':
      return generateFranchiseProfileRoute(params, column, externalUrls);
    case 'opponent_name':
      return generateFranchiseProfileRoute(params, column, externalUrls);
    case 'franchise_season__abbr_3':
      return generateFranchiseProfileRoute(params, column, externalUrls);
    case 'opponent_season__abbr_3':
      return generateFranchiseProfileRoute(params, column, externalUrls);
    case 'game__date':
      return generateBoxscoreRoute(params, 'game_id', externalUrls);
    case 'first':
      return generateBoxscoreRoute(params, 'first_id', externalUrls);
    case 'last':
      return generateBoxscoreRoute(params, 'last_id', externalUrls);
    default:
      return '';
  }
};

export const renderCell = (
  column: string,
  externalUrls: ExternalLeagueRoutesType,
) =>
  function generate(params: GridCellParams<string>) {
    const value = params.row[column];
    const route = generateLinkRoute(column, params, externalUrls);
    return route !== '' ? <a href={route} target="_blank" rel="noreferrer">{value}</a> : value;
  };
