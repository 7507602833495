import React from 'react';
import { Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';


interface IDataMap {
  [key: string]: string;
}

interface IProps {
  uniqueRowKey: string;
  groupings: string[][];
  datasets: any[][];
  dataMaps: IDataMap[];
  labelHeaderRow?: React.ReactElement;
  groupingCellStyles?: any;
  tableCellStyles?: any;
  size?: 'small' | 'medium';
  tableFooter?: React.ReactElement;
};


const GroupingTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.custom.lighterGrey,
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.grouping-cell`]: {
    fontWeight: 600,
  },
  [`&:first-child`]: {
    paddingLeft: 20,
  },
  [`&:last-child`]: {
    paddingRight: 20,
  }
}));

const NameCell = styled(StyledTableCell)(() => ({
  textAlign: 'left',
  padding: '20px 10px',
}));

const StatCell = styled(StyledTableCell)(() => ({
  textAlign: 'center',
  padding: 0,
  width: 0,
}));

const EMPTY_GROUPINGS = [
  'actions',
  'created',
];


function StyledGroupingTable({
  uniqueRowKey,
  groupings,
  datasets,
  dataMaps,
  labelHeaderRow,
  groupingCellStyles,
  tableCellStyles={},
  size,
  tableFooter
}: IProps) {
  const cellValue = (attribute: string, index: number, value: any) => {
    switch (attribute.toLowerCase()) {
      case 'row':
        return index + 1;
      default:
        return value;
    }
  }

  const tableHeader = (
    <TableHead>
      { labelHeaderRow }
    </TableHead>
  );

  const bodyPartial = (
    <TableBody>
      { 
        datasets.map((data: any[], index: number) => {
          const currentGrouping = groupings[index];

          return (
            <>
              <GroupingTableRow key={ uniqueRowKey }>
                <NameCell 
                  key={currentGrouping[0]} 
                  sx={groupingCellStyles}
                  className="grouping-cell"
                >
                    {currentGrouping[0]}
                </NameCell>
                {
                  currentGrouping.slice(1).map((grouping: string) => {
                    const displayGrouping = EMPTY_GROUPINGS.includes(grouping.toLowerCase()) ? '' : grouping;
                    return <StatCell key={grouping} align="right" sx={groupingCellStyles} className="grouping-cell">
                      {displayGrouping}
                    </StatCell>;
                  })
                }
              </GroupingTableRow>
              {
                data.map((row: any, rowIndex: number) => (
                  <TableRow
                    key={row[uniqueRowKey]}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      {
                        currentGrouping.map((grouping: string, groupingIndex: number) => {
                          const key = `${row[uniqueRowKey]}-${grouping}`;
                          const attribute = dataMaps[index][grouping];
                          const value = row[attribute];

                          const CellComponent = groupingIndex === 0 ? NameCell : StatCell;

                          return (
                            <CellComponent key={ key } align="right" sx={tableCellStyles}>
                              { cellValue(attribute, rowIndex, value) }
                            </CellComponent>
                          )
                        })
                      }
                  </TableRow>
                ))
              }
            </>
          );
        })
      }

    </TableBody>
  );

  return (
    <TableContainer component={Paper} style={{ borderRadius: '7px' }} variant='outlined'>
      <Table size={size}>
        { tableHeader }
        { bodyPartial }
        { tableFooter }
      </Table>
    </TableContainer>
  );
};

export default StyledGroupingTable;
