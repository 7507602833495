import React from 'react';
import { Box, FormControl, Radio, RadioGroup } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  LabelStyles,
  SubLabelStyles,
} from './StyleOverrides';

import EliasNumericInput from '../EliasInput/EliasNumericInput';
import EliasTextInput from '../EliasInput/EliasTextInput';
import { ModalRadioFilterType } from '../../types/modal';


interface IProps {
  selectedValue: string;
  filterId: string;
  radioFilters: ModalRadioFilterType[];
  inputValue: string;
  inputEndValue: string;
  saveInputValue: (value: string, contextId: string, filter: ModalRadioFilterType, end?: boolean) => void,
  handleRadioButtonChange?: (
    option: string,
    value: string,
    chipAbbr: string,
  ) => void;
}

function ModalRadioGroup({
  selectedValue,
  filterId,
  radioFilters,
  inputValue,
  saveInputValue,
  inputEndValue,
  handleRadioButtonChange = () => {},
}: IProps) {
  const isValidGameNumberOfSeries = (value: string) =>
    value === '' || (Number(value) >= 1 && Number(value) <= 7);

  const isValidDate = (date: string) => {
    if (date === '') return true;
    const validDate = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    const dateMatch = date.match(validDate);
    return dateMatch !== null;
  };
  
  const isValidSeason = (date: string) => {
    // TODO: understand why I also had to do a null/undefined check here
    if (date === '' || date === undefined) return true;
    const validDate = /^\d{4}-\d{2}$/;
    const dateMatch = date.match(validDate);
    return dateMatch !== null;
  };

  const validateInput = (date: string, filter: ModalRadioFilterType): boolean => {
    if (filter.type === 'custom_date_range') {
      return isValidDate(date)
    }
    if (filter.inputValidation) {
      return filter.inputValidation(inputValue)
    }
    return isValidSeason(inputValue);
  };

  const setInputValue = (value: string, filter: ModalRadioFilterType) =>
    saveInputValue(value, filterId, filter);

  const setInputEndValue = (value: string, filter: ModalRadioFilterType) =>
    saveInputValue(value, filterId, filter, true);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: string, chipLabel: string) =>
    handleRadioButtonChange(filterId, value, chipLabel);

  const radioButtonInput = (filter: ModalRadioFilterType) => {
    if (filter.type === 'custom_game_number') {
      return (
        <EliasNumericInput
          id={filter.id}
          value={inputValue}
          onChange={(value: string) => setInputValue(value, filter)}
          ariaLabel={`${filter.label}-value`}
          isInputValid={isValidGameNumberOfSeries(inputValue)}
          placeholder=""
        />
      );
    }

    if (filter.type === 'custom_date_range' || filter.type === 'custom_season_range') {
      return (
        <>
          <EliasTextInput
            id={filter.id}
            value={inputValue}
            onChange={(value: string) => setInputValue(value, filter)}
            ariaLabel={`${filter.label}-value`}
            isInputValid={validateInput(inputValue, filter)}
            placeholder={
              filter.type === 'custom_date_range'
                ? 'MM/DD/YYYY - MM/DD/YYYY'
                : (filter.placeholderText ?? 'YYYY-YY')
            }
          />{' '}
          -{' '}
          <EliasTextInput
            id={filter.id}
            value={inputEndValue}
            onChange={(value: string) => setInputEndValue(value, filter)}
            ariaLabel={`${filter.label}-value`}
            isInputValid={validateInput(inputEndValue, filter)}
            placeholder={
              filter.type === 'custom_date_range'
                ? 'MM/DD/YYYY - MM/DD/YYYY'
                : (filter.placeholderText ?? 'YYYY-YY ')
            }
          />
        </>
      );
    }

    return <p>Check config</p>;
  };

  return (
    <Box>
      <FormControl variant="standard">
        <RadioGroup
          aria-labelledby="radio-buttons"
          name="elias-radio-buttons"
          value={selectedValue}
        >
          {
            radioFilters.map((option: ModalRadioFilterType) => (
              <Box key={`${option.id}-option-box`}>
                <FormControlLabel
                  key={`${option.id}-option`}
                  value={option.id}
                  control={<Radio onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event, option.id, option.chip)} />}
                  label={option.label}
                  sx={LabelStyles}
                  name={option.chip}
                />
                { option.type !== 'default' && radioButtonInput(option) }
                {option.subLabel && (
                  <FormHelperText sx={SubLabelStyles}>
                    {option.subLabel}
                  </FormHelperText>
                )}
              </Box>
            ))
          }
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

export default ModalRadioGroup;
