import { StatFilter, StatFilterType } from "../../../compounds/EliasStatsFilter/filter-stats-utils";

export type FinderSelectOptionType = {
  id: string;
  label: string;
};

export const PAGE_TITLE = "NCAA Men's Basketball Player Span Finder";
export const PAGE_SUBTITLE =
  'Search the most complete and accurate NCAA MBB database for player game stats, that occurred within a span of games.';

export const spanRangeOptions: FinderSelectOptionType[] = [
  { id: 'none', label: 'Any' },
  { id: 'single-season', label: 'Single Season' },
  { id: 'current', label: 'Current' },
  { id: 'current-single-season', label: 'Current (single season only)' },
];

export const DEFAULT_SPAN_RANGE_OPTION = spanRangeOptions[0].id;

export const statsFilters: StatFilter[] = [
  {
    id: 'team_win',
    abbr: 'WIN',
    name: 'Team Win',
    type: StatFilterType.equality,
    explanation: '# of Wins',
  },
  {
    id: 'team_loss',
    abbr: 'LOSS',
    name: 'Team Loss',
    type: StatFilterType.equality,
    explanation: '# of Losses',
  },
  {
    id: 'points',
    abbr: 'PTS',
    name: 'Points',
    type: StatFilterType.equality,
  },
  {
    id: 'points_per_game',
    abbr: 'PTS/G',
    name: 'Points Per Game',
    type: StatFilterType.equality,
    searchModifier: (value: string, modifierValue: number) => {
      const result = Number(value) * modifierValue;
      return result.toString();
    },
    conflictingStatIds: ['points'],
  },
  {
    id: 'total_rebounds',
    abbr: 'REB',
    name: 'Rebounds',
    type: StatFilterType.equality,
  },
  {
    id: 'total_rebounds_per_game',
    abbr: 'REB/G',
    name: 'Rebounds Per Game',
    type: StatFilterType.equality,
    searchModifier: (value: string, modifierValue: number) => {
      const result = Number(value) * modifierValue;
      return result.toString();
    },
    conflictingStatIds: ['total_rebounds'],
  },
  {
    id: 'assists',
    abbr: 'AST',
    name: 'Assists',
    type: StatFilterType.equality,
  },
  {
    id: 'assists_per_game',
    abbr: 'AST/G',
    name: 'Assists Per Game',
    type: StatFilterType.equality,
    searchModifier: (value: string, modifierValue: number) => {
      const result = Number(value) * modifierValue;
      return result.toString();
    },
    conflictingStatIds: ['assists'],
  },
  {
    id: 'steals',
    abbr: 'STL',
    name: 'Steals',
    type: StatFilterType.equality,
  },
  {
    id: 'blocks',
    abbr: 'BLK',
    name: 'Blocks',
    type: StatFilterType.equality,
  },
  {
    id: 'double_double',
    abbr: 'DDBL',
    name: 'Double Double',
    type: StatFilterType.boolean,
  },
  {
    id: 'triple_double',
    abbr: 'TDBL',
    name: 'Triple Double',
    type: StatFilterType.boolean,
  },
  {
    id: 'field_goals_made',
    abbr: 'FG',
    name: 'Field Goals',
    type: StatFilterType.equality,
  },
  {
    id: 'field_goal_attempts',
    abbr: 'FGA',
    name: 'Field Goal Attempts',
    type: StatFilterType.equality,
  },
  {
    id: 'field_goal_percentage',
    abbr: 'FG%',
    name: 'Field Goal %',
    type: StatFilterType.equality,
    explanation: '.xxx format',
  },
  {
    id: 'free_throws_made',
    abbr: 'FT',
    name: 'Free Throws',
    type: StatFilterType.equality,
  },
  {
    id: 'free_throw_attempts',
    abbr: 'FTA',
    name: 'Free Throw Attempts',
    type: StatFilterType.equality,
  },
  {
    id: 'free_throw_percentage',
    abbr: 'FT%',
    name: 'Free Throw %',
    type: StatFilterType.equality,
    explanation: '.xxx format',
  },
  {
    id: 'three_point_field_goals_made',
    abbr: 'FG3M',
    name: 'Three Point Field Goals',
    type: StatFilterType.equality,
  },
  {
    id: 'three_point_field_goal_attempts',
    abbr: 'FG3A',
    name: 'Three Point FG Attempts',
    type: StatFilterType.equality,
  },
  {
    id: 'three_point_field_goal_percentage',
    abbr: 'FG3%',
    name: 'Three Point FG %',
    type: StatFilterType.equality,
    explanation: '.xxx format',
  },
  {
    id: 'turnovers',
    abbr: 'TO',
    name: 'Turnovers',
    type: StatFilterType.equality,
  },
  {
    id: 'personal_fouls',
    abbr: 'PF',
    name: 'Personal Fouls',
    type: StatFilterType.equality,
  },
  {
    id: 'minutes',
    abbr: 'MIN',
    name: 'Minutes',
    type: StatFilterType.equality,
  },
  {
    id: 'offensive_rebounds',
    abbr: 'OREB',
    name: 'Offensive Rebounds',
    type: StatFilterType.equality,
  },
  {
    id: 'defensive_rebounds',
    abbr: 'DREB',
    name: 'Defensive Rebounds',
    type: StatFilterType.equality,
  },
  {
    id: 'disqualified',
    abbr: 'DQ',
    name: 'Disqualified',
    type: StatFilterType.boolean,
  },
  {
    id: 'franchise_score',
    abbr: 'TM Score',
    name: 'Team Score',
    type: StatFilterType.equality,
  },
  {
    id: 'opponent_score',
    abbr: 'OPP Score',
    name: 'Opponent Score',
    type: StatFilterType.equality,
  },
  {
    id: 'score_difference',
    abbr: 'SC Diff',
    name: 'Score Difference',
    type: StatFilterType.equality,
  },
  // {
  //   id: 'started',
  //   abbr: 'GS',
  //   name: 'Game Started',
  //   year: 1970,
  //   type: StatFilterType.boolean,
  //   explanation: '1=Game Started, 0=Off-the-Bench',
  // },
];
