import React from 'react';
import { Box, FormControl, FormHelperText, Grid, InputAdornment, OutlinedInput } from '@mui/material';
import WhatshotRoundedIcon from '@mui/icons-material/WhatshotRounded';
import FinderSelect from '../../atoms/FinderSelect/FinderSelect';
import './PlayerStreakFilters.scss';
import { DEFAULT_STREAK_RANGE_OPTION, FinderSelectOptionType } from '../../pages/Nba/PlayerStreakFinder/constants';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';

interface IProps {
  streakRangeOptions: FinderSelectOptionType[];
};

function PlayerStreakFilters({ streakRangeOptions }: IProps) {
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();

  const handleStreakOptions = (selection: string) => {
    filterDispatchContext({
      type: 'streakOptionsSelected',
      streakRangeOption: selection,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    filterDispatchContext({
      type: 'changeStreakGameNumber',
      streakGameNumber: newValue,
    });
  };

  return (
    <>
      <Grid item xs={4}>
        <Box>
          <FormControl sx={{ width: '100%' }} variant="outlined">
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <WhatshotRoundedIcon className="fire-icon" />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  * Required
                </InputAdornment>
              }
              placeholder='Streak Length'
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              onChange={handleChange}
              value={filterContext?.streakGameNumber}
              required
              error={!!filterContext?.streakGameNumber && Number(filterContext?.streakGameNumber) < 2}
            />
            <FormHelperText>
              { filterContext?.streakGameNumber && filterContext?.streakGameNumber.length > 0 && filterContext?.streakGameNumber < 2 && filterContext?.streakGameNumber >= 0 ?
                  'Streak length must be at least 2 games' :
                  'Enter minimum number of games in streak'
              }
            </FormHelperText>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <FinderSelect
          label='Streak Options'
          value={filterContext.streakRangeOption || DEFAULT_STREAK_RANGE_OPTION}
          options= {streakRangeOptions}
          handleSelection={handleStreakOptions}
          selectStyleOverrides={{
            select: { 'minHeight': '56px' },
            formControl: { width: '100%' }
          }}
        />
      </Grid>
    </>
  );
}

export default PlayerStreakFilters;
