import React from "react";
import { Link } from 'react-router-dom';
import ROUTER_URLS from '../../../utils/router/urls';

// add interface here so we can append the link without type errors
interface DataInfoInterface {
  category: string;
  season: string;
  notes: React.ReactNode;
};

const backInTimeInfo: DataInfoInterface[] = [
  {
    category: 'Team Scores: Current D1 Schools',
    season: '1948-49',
    notes: ''
  },
  {
    category: 'Team Scores: Big Ten Conference',
    season: '1905-06',
    notes: ''
  },
  {
    category: 'Team Scores: MAC Conference',
    season: '1946-47',
    notes: ''
  },
  {
    category: 'Team Scores: PAC-12 Conference',
    season: '1940-41',
    notes: ''
  },
  {
    category: 'Team Scores: SEC Conference',
    season: '1932-33',
    notes: ''
  },
  {
    category: 'AP Ranks',
    season: '1948-49',
    notes: ''
  },
  {
    category: 'NCAA Tournament Seeds',
    season: '1978-79',
    notes: ''
  },
  {
    category: 'Halftime Team Scores',
    season: '1996-97',
    notes: ''
  },
  {
    category: 'Halftime Team Scores: NCAA Tournament',
    season: '1938-39',
    notes: ''
  },
  {
    category: 'Team and Differential Stats: Regular Season',
    season: '2005-06',
    notes: 'E.g.: PTS, SC Diff, REB, REB DIFF, AST, AST DIFF, etc'
  },
  {
    category: 'Team and Differential Stats: Big East Conference',
    season: '1996-97',
    notes: ''
  },
  {
    category: 'Team and Differential Stats: PAC-12 Conference',
    season: '2001-02',
    notes: ''
  },
  {
    category: 'Team and Differential Stats: All Tournaments',
    season: '',
    notes: <Link to={ROUTER_URLS.ncaambb.dataInfo}>See Player Game Stats Data Coverage</Link>
  }
];

export default backInTimeInfo;
