import { datadogRum } from '@datadog/browser-rum';

const environment = process.env.REACT_APP_ENVIRONMENT || 'dev';
const version = `${process.env.REACT_APP_VERSION}-${environment.substring(0, 3)}`;


function datadogInit() {
  if (environment !== 'prod' && environment !== 'qa') {
    return; // nothing to instrument in dev
  }

  datadogRum.init({
    applicationId: '4e3b790c-c593-4d2e-b262-1c12f584c19b',
    clientToken: 'pub47f61405fefc4bffc66781480868251c',
    site: 'datadoghq.com',
    service: 'access-elias-frontend',
    env: environment,
    // Specify a version number to identify the deployed version of your application in Datadog
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'mask-user-input',
    version
  });

  datadogRum.startSessionReplayRecording();
}

export default datadogInit;
