import React from 'react';
import ROUTER_URLS from '../../../utils/router/urls';
import Header from '../../../compounds/Common/Header';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import { statsFilters, spanRangeOptions, PAGE_TITLE, PAGE_SUBTITLE } from './constants';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import {
  advancedModalFilterCategories,
  gameModalFilterCategories,
} from '../constants';
import { ContextFilterType } from '../../../types/context';
import NbaTeamFiltersLayout from '../../../compounds/nba/NbaTeamFiltersLayout';
import withCopyableUrl, {
  WithCopyableUrlProps,
} from '../../../compounds/CopyableUrlWrapper/CopyableUrlWrapper';
import FinderStackLayout from '../../../compounds/FinderStackLayout/FinderStackLayout';
import PlayerSpanFilters from '../../../compounds/PlayerSpanFilters/PlayerSpanFilters';
import { appendContextFilters } from '../../../utils/nba/search';

interface IProps extends WithCopyableUrlProps {}

function TeamSpanFinderSearch({ handleDone }: IProps) {
  const pageSpecificSearchEnabled = (context: ContextFilterType): boolean =>
    Boolean(context.spanLength) && Number(context.spanLength) > 1;

  const statsFilterPartial = (
    <EliasStatsFilter
      statsFilterOptions={statsFilters}
      displayYearInfo
    />
  );
  const gameTypeModalPartial = (
    <ModalFilters
      buttonTitle='Game Type & Date Filters'
      filters={gameModalFilterCategories}
      filterType='gameTypeFilters'
    />
  );
  const advancedModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={advancedModalFilterCategories}
      filterType='advancedFilters'
      comingSoon
    />
  );

  return (
    <Dashboard>
      <FinderStackLayout
        header={<Header title={PAGE_TITLE} subtitle={PAGE_SUBTITLE} />}
        topPanel={
          <NbaTeamFiltersLayout
            handleDone={handleDone}
            statsFilterPartial={statsFilterPartial}
            pageSpecificSearchEnabled={pageSpecificSearchEnabled}
            apiUrls={API_URLS.nba}
            topModalPartial={gameTypeModalPartial}
            bottomModalPartial={advancedModalPartial}
          >
            <PlayerSpanFilters spanRangeOptions={spanRangeOptions} />
          </NbaTeamFiltersLayout>
        }
      />
    </Dashboard>
  );
}

export default withCopyableUrl(
  TeamSpanFinderSearch,
  ROUTER_URLS.nba.teamSpanFinderResults,
  statsFilters,
  appendContextFilters
);
