import React from 'react';
import { useParams } from 'react-router-dom';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import GameLayout from '../../../compounds/GameLayout/GameLayout';
import PageTitle from '../../../atoms/PageTitle/PageTitle';
import API_URLS from '../../../../api/api_urls';
import { useFetchGameBoxScore } from '../../../hooks/useFetch';
import { formatDate } from '../../../utils/common/date';

function NcaaMbbGame() {
  const { gameId } = useParams();
  const apiUrl = API_URLS.ncaambb;

  const { data: gameData, isLoading: loading, error } = useFetchGameBoxScore(gameId || '', apiUrl.boxscore);

  if (loading) {
    return <span>Loading...</span>
  }

  if (error && error instanceof Error) {
    return <span>Error: {error.message}</span>
  }

  if (!gameData) {
    return <span>Error: failed to load game data</span>
  }

  const { overview, date } = gameData;
  const { visitor, home } = overview;
  const dateDisplay = formatDate(date, "LLLL d, yyyy");
  const title = `${visitor?.short_name} vs. ${home?.short_name} - ${dateDisplay}`;
  return (
    <>
      <PageTitle
        title={title}
        titleTemplate="%s - NCAA Men's Basketball"
      />
      <Dashboard>
        <GameLayout gameData={gameData} />
      </Dashboard>
    </>
  );
}

export default NcaaMbbGame;
