import React from 'react';
import TeamGameTable from '../../../compounds/TeamGameTable/TeamGameTable';
import EliasBackButton from '../../../atoms/EliasBackButton/EliasBackButton';
import ROUTER_URLS from '../../../utils/router/urls';
import ResultsLoading from '../../../compounds/ResultsLoading/ResultsLoading';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import { fetchWnbaTeamSpanStats } from '../../../../api/wnba';
import { spanRangeOptions, statsFilters, PAGE_TITLE, PAGE_SUBTITLE } from './constants';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import {
  advancedModalFilterCategories,
  gameModalFilterCategories,
} from '../constants';
import { ContextFilterType } from '../../../types/context';
import WnbaTeamFiltersLayout from '../../../compounds/wnba/WnbaTeamFiltersLayout';
import { mapTeamRows } from '../../../utils/wnba/span-finder/map-results';
import { mapTeamColumns } from '../../../utils/wnba/span-finder/map-columns';
import withResultsLayout, {
  WithResultsLayoutProps,
} from '../../../compounds/ResultsWrapper/ResultsWrapper';
import FinderStackLayout from '../../../compounds/FinderStackLayout/FinderStackLayout';
import PlayerSpanFilters from '../../../compounds/PlayerSpanFilters/PlayerSpanFilters';
import { appendContextFilters } from '../../../utils/wnba/search';
import Header from '../../../compounds/Common/Header';
import EXTERNAL_URLS from '../../../utils/router/external_urls';

interface IProps extends WithResultsLayoutProps {}

const LOADING_MESSAGE =
  'Spans are data intensive and may take longer than other finders to produce results.';

function TeamStreakFinderResults({
  columnHeaders,
  resultsData,
  tableDataLoading,
  filtersLoading,
  error,
  handleDone,
  pageOrSortDataFetch,
}: IProps) {
  const pageSpecificSearchEnabled = (context: ContextFilterType): boolean =>
    Boolean(context.spanLength) && Number(context.spanLength) > 1;

  const statsFilterPartial = (
    <EliasStatsFilter
      statsFilterOptions={statsFilters}
      displayYearInfo={false}
    />
  );
  const gameTypeModalPartial = (
    <ModalFilters
      buttonTitle='Game Type & Date Filters'
      filters={gameModalFilterCategories}
      filterType='gameTypeFilters'
    />
  );
  const advancedModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={advancedModalFilterCategories}
      filterType='advancedFilters'
      comingSoon
    />
  );

  return (
    <Dashboard loading={filtersLoading}>
      <FinderStackLayout
        header={
          <Header
            title={PAGE_TITLE}
            subtitle={PAGE_SUBTITLE}
            backButton={
              <EliasBackButton route={ROUTER_URLS.wnba.teamSpanFinderSearch} />
            }
          />
        }
        topPanel={
          <WnbaTeamFiltersLayout
            handleDone={handleDone}
            statsFilterPartial={statsFilterPartial}
            pageSpecificSearchEnabled={pageSpecificSearchEnabled}
            apiUrls={API_URLS.wnba}
            topModalPartial={gameTypeModalPartial}
            bottomModalPartial={advancedModalPartial}
          >
            <PlayerSpanFilters spanRangeOptions={spanRangeOptions} />
          </WnbaTeamFiltersLayout>
        }
        bottomPanel={
          tableDataLoading || error ? (
            <ResultsLoading error={error} loadingMessage={LOADING_MESSAGE} />
          ) : (
            <TeamGameTable
              data={resultsData?.results ?? []}
              dataCount={resultsData?.count ?? 0}
              dataMetaInfo={resultsData?.query?.back_to ?? []}
              columnData={columnHeaders || []}
              handleDone={pageOrSortDataFetch}
              linkUrls={EXTERNAL_URLS.WNBA}
            />
          )
        }
      />
    </Dashboard>
  );
}

export default withResultsLayout(
  TeamStreakFinderResults,
  ROUTER_URLS.wnba.teamSpanFinderResults,
  statsFilters,
  fetchWnbaTeamSpanStats,
  mapTeamColumns,
  mapTeamRows,
  appendContextFilters
);
