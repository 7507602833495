import theme from '../../utils/theme/theme';

export const InputStyles = {
  input: {
    textAlign: 'center',
  },
  '.MuiOutlinedInput-root': {
    width: 50,
    height: 36,
    backgroundColor: '#fff',
  },
  '& fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: `${theme.palette.custom.mediumDarkGrey}`,
    borderRadius: 2,
  },
};

export const DateInputStyles = {
  ...InputStyles,
  '.MuiOutlinedInput-root': {
    width: 136,
    height: 36,
  },
};
