import { NCAA_TOURNAMENT_ID, POST_SEASON_TOURNAMENTS, CONF_TOURNAMENTS, NCAA_ROUNDS, DEFAULT_TOURNAMENT_ROUND_LABELS } from "../ncaambb/constants";
import { teamAdvancedModalFilterCategories } from "../../pages/NcaaMbb/constants";
import { ModalFilterCategoriesType } from "../../types/modal";

export const getKeyByValue = (object: any, value: any): string | undefined => Object.keys(object).find(key => object[key] === value);

const getFilterCategoryType = (category: string): ModalFilterCategoriesType | undefined => teamAdvancedModalFilterCategories.find(c => c.category === category);

const getFilterLabel = (category: string, filterId: string): string => {  
  const filterCategoryType: ModalFilterCategoriesType | undefined = getFilterCategoryType(category);
  const filterLabel: string = filterCategoryType?.filters?.find(f => f.id === filterId)?.label || '';

  return filterLabel;
}

export const formatTournamentNameDisplay = (league: string, tournamentId: number, tournamentName: string): string => {
  let tournamentNameDisplay: string = '';
  let tournamentKey: string | undefined;

  switch (league) {
    case 'ncaambb':
      tournamentKey = getKeyByValue(POST_SEASON_TOURNAMENTS, tournamentId);

      if (tournamentKey) {
        if (tournamentId === NCAA_TOURNAMENT_ID) {
          tournamentNameDisplay = getFilterCategoryType('ncaa_tournament')?.categoryLabel || '';
        }
        else {
          tournamentNameDisplay = getFilterLabel('other_postseason_tournaments', tournamentKey);
        }
      }
      else {
        tournamentKey = getKeyByValue(CONF_TOURNAMENTS, tournamentId);

        if (tournamentKey) {
          tournamentNameDisplay = getFilterLabel('conference_tournaments', tournamentKey);
        }
      }
      break;

    default:
      break;
  }

  return tournamentNameDisplay ?? tournamentName;
};

export const formatRoundNameDisplay = (league: string, tournamentId: number, roundAbbreviation: string): string => {
  let roundNameDisplay: string = '';
  let roundKey: string | undefined;

  switch (league) {
    case 'ncaambb':
      if (tournamentId === NCAA_TOURNAMENT_ID) {
        roundKey = getKeyByValue(NCAA_ROUNDS, roundAbbreviation);

        if (roundKey) {
          roundNameDisplay = getFilterCategoryType('ncaa_tournament')?.filters.find(f => f.id === roundKey)?.label || '';
        }
      }
      else {
        roundNameDisplay = DEFAULT_TOURNAMENT_ROUND_LABELS[roundAbbreviation];
      }
      break;

    default:
      break;
  }

  return roundNameDisplay ?? roundAbbreviation;
}