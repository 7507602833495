import React from 'react';
import ellipsis from './animated-ellipsis.gif';

function EllipsisLoading() {
  return (
    <img
      src={ellipsis}
      style={{ width: '100px', margin: 'auto', display: 'block' }}
      alt="Loading..."
    />
  );
}

export default EllipsisLoading;
