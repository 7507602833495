import { Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../compounds/Common/Header';
import ROUTER_URLS from '../utils/router/urls';

const camelCaseToSentenceCase = (text: string): string => {
  const replaced = text.replace(/([A-Z])/g, " $1");
  const result = replaced.charAt(0).toUpperCase() + replaced.slice(1);
  return result;
}

function Home() {
  return (
    <>
      <Header title="AccessElias" />
      {
        Object.keys(ROUTER_URLS.nba).map((key: string) => (
          <Grid key={key} margin={2}>
            <Link to={ROUTER_URLS.nba[key as keyof typeof ROUTER_URLS.nba] || "/"}>NBA {camelCaseToSentenceCase(key)}</Link>
          </Grid>
        ))
      }
      {
        Object.keys(ROUTER_URLS.ncaambb).map((key: string) => (
          <Grid key={key} margin={2}>
            <Link to={ROUTER_URLS.ncaambb[key as keyof typeof ROUTER_URLS.ncaambb] || "/"}>NCAA MBB {camelCaseToSentenceCase(key)}</Link>
          </Grid>
        ))
      }
      {
        Object.keys(ROUTER_URLS.wnba).map((key: string) => (
          <Grid key={key} margin={2}>
            <Link to={ROUTER_URLS.wnba[key as keyof typeof ROUTER_URLS.wnba] || "/"}>WNBA {camelCaseToSentenceCase(key)}</Link>
          </Grid>
        ))
      }
      <Grid margin={2}>
        <Link to={ROUTER_URLS.changePassword}>Change Password</Link>
      </Grid>
    </>
  );
}

export default Home;
