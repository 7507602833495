import React from 'react';
import { Button, ButtonGroup, styled } from '@mui/material';

interface IProps {
  selected: number;
  values: string[];
  onChange: (index: number) => void;
  disabled?: boolean;
}

const StyledButton = styled(Button)((props: { selected: boolean }) => ({
  backgroundColor: props.selected ? '#EFF8FF' : '#ffffff',
  color: props.selected ? '#175CD3' : '#101828',
  fontWeight: 600,
  fontSize: '14px',  
}));

function PillButtonGroup({ selected, values, onChange, disabled }: IProps) {
  return (
    <ButtonGroup
      aria-label="radius button group"
      sx={{ '--ButtonGroup-radius': '40px', marginX: 3 }}
      disabled={disabled}
    >
      {
        values.map((value: string, index: number) => 
          <StyledButton key={value} selected={selected === index} onClick={() => onChange(index)}>{value}</StyledButton>  
        )
      }
    </ButtonGroup>
  );
}

export default PillButtonGroup;
