import * as React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import LeftDrawer from './LeftDrawer';
import SearchNavBar from './SearchNavBar';
import EllipsisLoading from '../Common/EllipsisLoading';
import { useLeagueDispatchContext } from '../../contexts/LeagueContext';

const drawerWidth = 275;

interface DashboardProps {
  children?: React.ReactNode;
  loading?: boolean;
}

// used styled components to get extremely particular styling in place for the layout
// for additional reading: https://mui.com/system/styled/#difference-with-the-sx-prop

const Contents = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  }),
}));

export default function Dashboard({ children, loading }: DashboardProps) {
  const location = useLocation();
  const leagueDispatchContext: any = useLeagueDispatchContext();
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    if (location) {
      const [, pathStart, ] = location.pathname.split('/');
      if (pathStart === 'tools') {
        leagueDispatchContext({
          type: 'leagueChanged',
          selectedLeague: 'nba',
        });
      }
      else if (pathStart === 'ncaambb') {
        leagueDispatchContext({
          type: 'leagueChanged',
          selectedLeague: 'ncaambb',
        });
      }
      else if (pathStart === 'wnba') {
        leagueDispatchContext({
          type: 'leagueChanged',
          selectedLeague: 'wnba',
        });
      }
    }
  }, [location, leagueDispatchContext]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box display="flex" flexDirection="column">
      <CssBaseline />
      <Box>
        <SearchNavBar toggle={toggleDrawer} />
      </Box>
      <Box>
        <LeftDrawer open={open} />
        <Contents open={open}>
          <Toolbar />
          { loading ?
            <Box style={{ alignItems: 'center', textAlign: 'center', width: '750px', marginTop: '100px' }}>
              <h3>Loading</h3>
              <EllipsisLoading />
            </Box> :
            children
          }
        </Contents>
      </Box>
    </Box>
  );
}
