import React from 'react';
import { Grid, Typography } from "@mui/material";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import NcaaMbbTeamLink from '../ncaambb/NcaaMbbTeamLink';

interface IProps {
  teamName: string;
  teamRecord: string;
  teamLink: string;
  teamLogoUrl?: string;
  teamScore: number;
  teamWin: boolean;
  rank?: number;
  rankType?: string;
}

function BoxScoreTeamRow({ teamName, teamRecord, teamLink, teamLogoUrl, teamScore, teamWin, rank, rankType }: IProps) {
  const rankDisplay = rankType === 'AP' ? `${rankType} #${rank}` : `#${rank} ${rankType}`;
  return (
    <Grid container direction='row'>
      <Grid item xs={10}>
        <Grid container direction='row'>
          <Grid
            item
            alignItems='center'
            alignContent='center'
            justifyContent='center'
            justifyItems='center'
            display='flex'
            xs={3}
          >
            {
              teamLogoUrl && <img src={teamLogoUrl} height="75" width="75" alt="team-logo" />
            }
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={0} direction='column' height='100%' justifyItems='center' justifyContent='center'>
              <Grid item>
              <Typography
                component="h4"
                variant="h6"
                fontWeight="600"
                display='flex'
                width='100%'
              >

                <NcaaMbbTeamLink link={ teamLink } name={ teamName } /> 
                { rank && 
                  <span style={{ marginLeft: '8px' }}>({rankDisplay})</span> 
                }
              </Typography>
              </Grid>
              <Grid item>
              <Typography
                component="div"
                variant='body1'
                display='flex'
                width='100%'
              >
                { teamRecord }
              </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Typography
          component="h4"
          variant="h6"
          fontWeight="600"
          alignItems='center'
          justifyContent='end'
          display='flex'
          height='100%'
        >
          { teamWin && <ArrowRightIcon /> }
          { teamScore }
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BoxScoreTeamRow;
