import React from 'react';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';
import FinderSelect from '../../atoms/FinderSelect/FinderSelect';
import { groupByOptions } from '../../pages/NcaaMbb/TeamGameFinder/constants';

function TeamGameGroupBy() {
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();

  const handleGroupBy = (selection: string) => {
    filterDispatchContext({
      type: 'groupBySelected',
      groupBy: selection,
      searchEnabled: true,
    });
  };

  return (
    <FinderSelect
      label='Count Results By'
      value={filterContext.groupBy || 'none'}
      options={groupByOptions}
      handleSelection={handleGroupBy}
    />
  );
}

export default TeamGameGroupBy;
