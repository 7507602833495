import React from 'react';
import { Grid, Modal } from '@mui/material';

interface IProps {
  open: boolean;
  handleClose: () => void;
  children: React.ReactElement;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 16,
  paddingTop: '1px',
  paddingBottom: '1px',
  height: '90%',
};

function StyledModal({ open, handleClose, children }: IProps) {

  const handleChange = () => {
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleChange}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid sx={style}>
        { children }
      </Grid>
    </Modal>
  );
};

export default StyledModal;
