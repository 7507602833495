import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import './PlayerFiltersLayout.scss';
import SearchButtonStyles from './StyleOverrides';
import { useFilterContext } from '../../contexts/FilterContext';
import TeamFilter from '../TeamFilter/TeamFilter';
import { searchEnabled } from '../../utils/wnba/search';
import { IApiUrlFinders } from '../../../api/api_urls';
import { ContextFilterType } from '../../types/context';
import FastFilters from '../FastFilters/FastFilters';
import ResetButton from '../../atoms/ResetButton/ResetButton';
import VenueFilter from '../VenueFilter/VenueFilter';
import StyledPaper from '../../atoms/StyledPaper/StyledPaper';


interface IProps {
  handleDone: () => void;
  pageSpecificSearchEnabled: (context: ContextFilterType) => boolean,

  statsFilterPartial: React.ReactNode;
  groupByPartial?: React.ReactNode;
  topModalPartial?: React.ReactNode;
  bottomModalPartial?: React.ReactNode;

  children?: React.ReactElement;

  apiUrls: IApiUrlFinders,
}

function WnbaTeamFiltersLayout({
  handleDone,
  pageSpecificSearchEnabled,
  statsFilterPartial,
  groupByPartial,
  children,
  apiUrls,
  topModalPartial,
  bottomModalPartial,
}: IProps) {
  const filterContext: any = useFilterContext();
  // const { host } = window.location;
  // const isProd = host.includes('accesselias-prod');
  const tempShowHalfQuarter = false;

  return (
    <StyledPaper variant='outlined'>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        paddingX={5}
        paddingY={2}
      >
        <FastFilters
          showActive={false}
          showRookie={false}
          showHomeAway
          showWinLoss
          showOvertime
          hideNeutralSite={false}
          showGameType={false}
          showHalfQuarter={tempShowHalfQuarter}
        />
        { topModalPartial }
        <Grid item xs={12}>
          { statsFilterPartial }
        </Grid>
        <Grid item xs={4}>
          <TeamFilter
            label="Team"
            actionType='changeTeamObject'
            actionObject='teamObject'
            apiUrl={apiUrls.franchise_season_manifest}
          />
        </Grid>
        <Grid item xs={4}>
          <TeamFilter
            label="Opponent"
            actionType='changeOpponentObject'
            actionObject='opponentObject'
            apiUrl={apiUrls.franchise_season_manifest}
          />
        </Grid>
        <Grid item xs={4}>
          <VenueFilter apiUrl={apiUrls.venue_manifest} />
        </Grid>
        { children }
        { bottomModalPartial }
        <Grid item xs={12} paddingTop='0px'>
          <Box className="horizontal-divider" />
        </Grid>
        <Grid container item xs={12} className="filter-buttons">
          <Grid item xs={1}>
            <ResetButton />
          </Grid>
          <Grid item xs={10} textAlign="right" paddingRight={5}>
            { groupByPartial }
          </Grid>
          <Grid item xs={1}>
            <Button
              sx={SearchButtonStyles}
              disabled={!searchEnabled(filterContext, pageSpecificSearchEnabled)}
              variant="contained"
              disableElevation
              onClick={handleDone}
            >
              {!filterContext.searchEnabled ? 'Update' : 'Search'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

export default WnbaTeamFiltersLayout;
