import { ColumnMappingType } from "../../../types/results";
import { SelectedStatFilterType } from "../../../types/statsFilter";
import { showHideColumns } from "../../common/results";
import { ContextFilterType } from '../../../types/context';
import { RESULT_COLUMNS } from "../../common/map-columns";

const WIDE_COL_WIDTH = 185; // fits longest player display name at 24 characters

export const ungroupedColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.playerDisplayName,
  RESULT_COLUMNS.gameDate,
  RESULT_COLUMNS.gameType,
  RESULT_COLUMNS.result,
  RESULT_COLUMNS.age,
  RESULT_COLUMNS.recordType,
  RESULT_COLUMNS.gameStarted,
  RESULT_COLUMNS.offensiveRebounds,
  RESULT_COLUMNS.defensiveRebounds,
  RESULT_COLUMNS.pointsInPaint,
  RESULT_COLUMNS.pointsOffTurnovers,
  RESULT_COLUMNS.dunks,
  RESULT_COLUMNS.secondChancePoints,
  RESULT_COLUMNS.tripleDouble,
  RESULT_COLUMNS.disqualified,
  RESULT_COLUMNS.personalFouls,
  RESULT_COLUMNS.franchiseScore,
  RESULT_COLUMNS.opponentScore,
  RESULT_COLUMNS.scoreDifference,
  RESULT_COLUMNS.lastGameResult,
  RESULT_COLUMNS.seasonGameNumber,
  RESULT_COLUMNS.franchiseWinPercentage,
  RESULT_COLUMNS.opponentWinPercentage,
  RESULT_COLUMNS.winPercentageDifference,
  RESULT_COLUMNS.pointsDifference,
  RESULT_COLUMNS.fieldGoalsDifference,
  RESULT_COLUMNS.freeThrowsDifference,
  RESULT_COLUMNS.threePointFieldGoalsDifference,
  RESULT_COLUMNS.totalReboundsDifference,
  RESULT_COLUMNS.personalFoulsDifference,
  RESULT_COLUMNS.turnoversDifference,
  RESULT_COLUMNS.stealsDifference,
  RESULT_COLUMNS.fieldGoalPercentageDifference,
  RESULT_COLUMNS.plusMinus,
  RESULT_COLUMNS.minutesDisplay,
  RESULT_COLUMNS.points,
  RESULT_COLUMNS.totalRebounds,
  RESULT_COLUMNS.assists,
  RESULT_COLUMNS.fieldGoalsMade,
  RESULT_COLUMNS.fieldGoalAttempts,
  RESULT_COLUMNS.fieldGoalPercentage,
  RESULT_COLUMNS.threePointFieldGoalsMade,
  RESULT_COLUMNS.threePointFieldGoalAttempts,
  RESULT_COLUMNS.threePointFieldGoalPercentage,
  RESULT_COLUMNS.freeThrowsMade,
  RESULT_COLUMNS.freeThrowAttempts,
  RESULT_COLUMNS.freeThrowPercentage,
  RESULT_COLUMNS.steals,
  RESULT_COLUMNS.blocks,
  RESULT_COLUMNS.turnovers,
];

export const ungroupedTeamColumnMapping: ColumnMappingType[] = ungroupedColumnMapping.map(col => {
  const { field } = col;

  // fields to remove
  if (['score_difference', 'minutes', 'minutes_display'].includes(field)) {
    return {
      ...col,
      field: 'remove',
    }
  }

  // fields to modify
  if (field === 'player__display_name') {
    return {
      ...col,
      field: 'franchise_display_name',
      headerName: 'Team',
    };
  }

  return col;
}).filter(col => col.field !== 'remove');

export const playerColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  {
    ...RESULT_COLUMNS.playerDisplayName,
    field: 'display_name',
  },
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const playerSeasonColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  {
    ...RESULT_COLUMNS.playerDisplayName,
    field: 'display_name',
  },
  RESULT_COLUMNS.season,
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const playerFranchiseColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  {
    ...RESULT_COLUMNS.playerDisplayName,
    field: 'display_name',
  },
  RESULT_COLUMNS.franchiseName,
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const playerOpponentColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  {
    ...RESULT_COLUMNS.playerDisplayName,
    field: 'display_name',
  },
  RESULT_COLUMNS.opponentName,
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const playerFranchiseSeasonColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  {
    ...RESULT_COLUMNS.playerDisplayName,
    field: 'display_name',
  },
  RESULT_COLUMNS.franchiseName,
  RESULT_COLUMNS.season,
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const playerOpponentSeasonColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  {
    ...RESULT_COLUMNS.playerDisplayName,
    field: 'display_name',
  },
  RESULT_COLUMNS.opponentName,
  RESULT_COLUMNS.season,
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const teamInstanceColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  // we have a franchise field that doesnt match the header or the api field name.
  // leave this in place until the api can be standardized
  {
    headerName: 'Team',
    field: 'franchise_display_name',
    width: WIDE_COL_WIDTH,
  },
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const teamSeasonColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  // we have a franchise field that doesnt match the header or the api field name.
  // leave this in place until the api can be standardized
  {
    headerName: 'Team',
    field: 'franchise_display_name',
    width: WIDE_COL_WIDTH,
  },
  RESULT_COLUMNS.season,
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const teamOpponentColumnMapping: ColumnMappingType[] = [
  RESULT_COLUMNS.rank,
  RESULT_COLUMNS.instancesCount,
  // we have a franchise field that doesnt match the header or the api field name.
  // leave this in place until the api can be standardized
  {
    headerName: 'Team',
    field: 'franchise_display_name',
    width: WIDE_COL_WIDTH,
  },
  RESULT_COLUMNS.opponentName,
  RESULT_COLUMNS.lastInstance,
  RESULT_COLUMNS.firstInstance,
];

export const mapPlayerColumns = (
  groupByColumn: string,
  localFilters: SelectedStatFilterType[],
  filterContext: ContextFilterType,
) => {
  switch (groupByColumn) {
    case 'player':
      return playerColumnMapping;
    case 'player-season':
      return playerSeasonColumnMapping;
    case 'player-franchise':
      return playerFranchiseColumnMapping;
    case 'player-opponent':
      return playerOpponentColumnMapping;
    case 'player-franchise-season':
      return playerFranchiseSeasonColumnMapping;
    case 'player-opponent-season':
      return playerOpponentSeasonColumnMapping;
    default:
      return showHideColumns(ungroupedColumnMapping, localFilters, filterContext);
  };
};

export const mapTeamColumns = (
  groupByColumn: string,
  localFilters: SelectedStatFilterType[],
  filterContext: ContextFilterType,
) => {
  switch(groupByColumn) {
    case 'team':
      return teamInstanceColumnMapping;
    case 'team-season':
      return teamSeasonColumnMapping;
    case 'team-opponent':
      return teamOpponentColumnMapping;
    default:
      return showHideColumns(ungroupedTeamColumnMapping, localFilters, filterContext);
  };
}

export const mapColumns = (
  groupByColumn: string,
  localFilters: SelectedStatFilterType[],
  filterContext: ContextFilterType,
  finderType: string = 'player',
) => {
  switch(finderType) {
    case 'player':
      return mapPlayerColumns(groupByColumn, localFilters, filterContext);
    case 'team':
      return mapTeamColumns(groupByColumn, localFilters, filterContext);
    default:
      return mapPlayerColumns(groupByColumn, localFilters, filterContext);
  };
}
