import React from 'react';
import Box from '@mui/system/Box';
import './EliasOptionList.scss';

interface IProps {
  displayYearInfo: boolean;
}

function EliasStatsListHeader({ displayYearInfo }: IProps) {
  return (
    <Box className="list-header">
      <Box sx={{ fontWeight: 'bold' }}>Stat</Box>
      {displayYearInfo && <Box sx={{ fontWeight: 'bold' }}>Recorded Since</Box>}
    </Box>
  );
}

export default EliasStatsListHeader;
