// we cannot come to a concensus on how decimals should look across the app
// the large number with decimals need to look considerably different than
// the percents. leaving two functions
export const floatToFixed = (
  value: string | number,
  places: number
): string => {
  if (value === null || value === undefined) return value;

  if (typeof value === 'number') {
    return parseFloat(value.toString()).toFixed(places);
  }
  return parseFloat(value).toFixed(places);
};

// note - there was a lot of feedback on decimals within the app
// while this looks over complicated (and possibly is) it handles
// all the various requirements. be careful making changes
export const percentToFixed = (
  value: string | number | null | undefined,
  places: number
): string => {
  if (value === null || value === undefined) return '';

  const parsedValue = parseFloat(value.toString());

  if (parsedValue < 0.001) {
    return '.000';
  }

  let finalValue = parsedValue;
  // floating point - trying to handle
  if (Math.trunc(parsedValue * 100) > 100) {
    finalValue = (parsedValue / 100)
  }

  return finalValue.toFixed(places).replace('0.', '.');
};

export default floatToFixed;