import React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import EliasDataGrid from '../../atoms/EliasDataGrid/EliasDataGrid';
import './TeamGameTable.scss';
import { GroupedTeamInstance, ResultDetail, BaseTeamGameResult } from '../../../api/types';
import CopyButton from '../../atoms/CopyButton/CopyButton';
import { createCsvString, formatResultDetail } from '../../utils/common/results';
import FavoriteButton from '../../atoms/FavoriteButton/FavoriteButton';
import { ExternalLeagueRoutesType } from '../../utils/router/external_urls';
import StyledPaper from '../../atoms/StyledPaper/StyledPaper';

interface IProps {
  data: BaseTeamGameResult[] | GroupedTeamInstance[];
  dataCount: number;
  dataMetaInfo: ResultDetail[];
  columnData: any;
  handleDone: (sortByParam: string, pageParam: number) => void;
  linkUrls: ExternalLeagueRoutesType;
  explanationMessage?: React.ReactElement;
}

function TeamGameTable({
  data,
  dataCount,
  dataMetaInfo,
  columnData,
  handleDone,
  linkUrls,
  explanationMessage,
}: IProps) {
  const total = dataCount;
  const resultExplanationText = explanationMessage || formatResultDetail(dataMetaInfo);

  const copyCsvDataFromResults = () => {
    const columnHeaders = columnData.map((column: any) => column.headerName);
    // game__game_class is a string for NBA, WNBA.  For NCAAMBB it is a JSX.Element so use game__game_class_string instead.
    const hasGameClassString: boolean = data.some(d => Object.hasOwn(d, 'game__game_class_string'));
    const fields = columnData.map((column: any) => {
      switch (column.field) {
        case 'result':          
          return 'result_string';
        case 'game__game_class':
          return hasGameClassString ? 'game__game_class_string' : 'game__game_class';
        default: 
          return column.field;
      }
    });
    const csv = createCsvString(columnHeaders, fields, data);
    navigator.clipboard.writeText(csv);
  };

  return (
    <StyledPaper variant='outlined'>
      <Grid2 container spacing={2} minHeight={75}>
        <Grid2 xs={2} display="flex" justifyContent="center" alignItems="center">
          <h3 className="grid-total player-game-results-total">{total} Results</h3>
        </Grid2>
        <Grid2 xs={6} display="flex" justifyContent="flex-start" alignItems="center">
          <p className="grid-explanation">{resultExplanationText}</p>
        </Grid2>
        <Grid2 xs={4} display="flex" justifyContent="center" alignItems="center">
          <FavoriteButton />
          <CopyButton onClick={copyCsvDataFromResults} />
        </Grid2>
      </Grid2>
      <EliasDataGrid
        columns={columnData}
        rows={data}
        rowCount={total}
        handleDone={handleDone}
        linkUrls={linkUrls}
      />
    </StyledPaper>
  );
}

export default TeamGameTable;
