import React from 'react';
import { Helmet } from "react-helmet";

interface IProps {
  title: string;
  titleTemplate?: string;
}

interface HelmetPropTypes {
  titleTemplate?: string;
}


function PageTitle({ title, titleTemplate }: IProps) {
  const helmetProps: HelmetPropTypes = {};
  if (titleTemplate) {
    helmetProps.titleTemplate = titleTemplate;
  };

  return (
    <Helmet {...helmetProps} >
      <title>{ title }</title>
    </Helmet>
  )
}

export default PageTitle;
