import React from 'react';
import TextField from '@mui/material/TextField';
import './EliasInput.scss';
import { InputStyles } from './StyleOverrides';
import {
  EliasInputProps,
  handleInputChange,
  handleInputFocus,
} from './elias-input-utils';

function EliasNumericInput({
  id,
  value,
  onChange,
  ariaLabel,
  focusedOption = '',
  isInputValid,
  isEditing = true,
  placeholder = undefined,
}: EliasInputProps) {
  const handleRef = (input: any) => {
    if (input !== null) {
      if (isEditing && focusedOption === id) {
        input.focus();
      } else if (!isEditing) {
        input.blur();
      }
    }
  };

  return (
    <TextField
      inputRef={handleRef}
      onFocus={handleInputFocus}
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
        'aria-label': ariaLabel,
        'data-testid': `${id}-value-input`,
      }}
      size="small"
      type="number"
      value={value}
      placeholder={placeholder}
      onChange={(event) => handleInputChange(event, onChange)}
      sx={InputStyles}
      error={isInputValid === undefined ? false : !isInputValid}
    />
  );
}

export default EliasNumericInput;
