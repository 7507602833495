import { ModalFilterCategoriesType } from "../../types/modal";

const CURRENT_SEASON = '2023-24';

export const advancedModalFilterCategories: ModalFilterCategoriesType[] = [
  {
    category: 'player',
    categoryLabel: 'Advanced Player Filters',
    filters: [
      {
        id: 'player_age',
        label: 'Age',
        subLabel: "Player’s age in years",
        searchTerms: ['Age'],
        filterType: 'equalityNumeric',
        chip: 'Age[EQUALITY_VALUE][INPUT_VALUE]',
      },
      {
        id: 'seasons',
        label: 'Seasons in League',
        subLabel: 'Number of seasons player has played in 1+ games',
        searchTerms: ['Years in League'],
        filterType: 'equalityNumeric',
        chip: 'Seasons in League[EQUALITY_VALUE][INPUT_VALUE]',
      },
      {
        id: 'career_game_number',
        label: 'Career Game Number',
        subLabel: 'Player’s NBA career game number',
        searchTerms: ['Career Game Number'],
        filterType: 'equalityNumeric',
        chip: 'Career Game Number[EQUALITY_VALUE][INPUT_VALUE]',
      },
      {
        id: 'season_game_number',
        label: 'Season Game Number',
        subLabel: 'Player’s NBA season game number',
        searchTerms: ['Season Game Number'],
        filterType: 'equalityNumeric',
        chip: 'Season Game Number[EQUALITY_VALUE][INPUT_VALUE]',
      },
      {
        id: 'debut_game',
        label: 'Career Debut',
        subLabel: 'Player’s NBA career debut',
        searchTerms: ['Career Debut'],
        filterType: 'checkbox',
        chip: 'Career Debut',
      },
      {
        id: 'ps_debut_game',
        label: 'Career Postseason Debut',
        subLabel: 'Player’s NBA postseason debut',
        searchTerms: ['Postseason Debut'],
        filterType: 'checkbox',
        chip: 'Postseason Debut',
      },
    ]
  },
];

// Opening Day
export const gameModalFilterCategories: ModalFilterCategoriesType[] = [
  {
    category: 'game_types',
    categoryLabel: 'Game Types',
    filters: [
      {
        id: 'reg',
        label: 'REG: Regular Season Games',
        subLabel: '',
        searchTerms: ['REG: Regular Season'],
        filterType: 'checkbox',
        chip: 'Regular Season'
      },
      {
        id: 'ps',
        label: 'PS: Postseason (AKA: Playoffs)',
        subLabel: 'Includes First Round, Conference Semifinals, Conference Finals and NBA Finals',
        searchTerms: ['PS: Postseason (AKA: Playoffs)'],
        filterType: 'checkbox',
        chip: 'Postseason'
      },
      {
        id: 'pin',
        label: 'PIN: Play-In Games',
        subLabel: '',
        searchTerms: ['PIN: Play-In Games'],
        filterType: 'checkbox',
        chip: 'Play-In'
      },
      // {
      //   id: 'as',
      //   label: 'ASG: All-Star Games',
      //   subLabel: '',
      //   searchTerms: ['ASG: All-Star Games'],
      //   filterType: 'checkbox',
      //   chip: 'All-Star Games',
      // },
    ]
  },
  {
    category: 'postseason',
    categoryLabel: 'Postseason Filters',
    filters: [
      {
        id: 'fin',
        label: 'NBA Finals',
        subLabel: '',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Finals'
      },
      {
        id: 'con',
        label: 'Conference Finals (AKA: NBA Semifinals)',
        subLabel: '',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Con. Finals'
      },
      {
        id: 'sem',
        label: 'Conference SemiFinals (AKA: NBA Quarterfinals)',
        subLabel: '',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Con. Semifinals'
      },
      {
        id: 'rd1',
        label: 'First Round',
        subLabel: '',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'Round 1',
      },
      {
        id: 'postseason_group',
        label: 'Postseason Group',
        subLabel: '',
        searchTerms: [''],
        filterType: 'radio',
        chip: 'postseason_group',
        radioGroup: [
          {
            id: 'game_franchise__game_number',
            label: 'Game Number of Series',
            subLabel: '',
            // Valid inputs are 1-7.
            value: '0',
            type: 'custom_game_number',
            chip: 'Series Game Number [INPUT_VALUE]'
          },
          {
            id: 'opening_game',
            label: 'Opening Game',
            subLabel: 'Results include opening games of a series only',
            value: '1',
            type: 'default',
            chip: 'Series Game Number 1'
          },
          {
            id: 'game_franchise__can_clinch',
            label: 'Can Clinch',
            subLabel: 'Results include games where player’s team can clinch only',
            value: '',
            type: 'default',
            chip: 'Can Clinch',
          },
          {
            id: 'game_franchise__can_be_eliminated',
            label: 'Facing Elimination',
            subLabel: 'Results include games where a player’s team faces elimination in a series only',
            value: '',
            type: 'default',
            chip: 'Face Elimination',
          },
          {
            id: 'game_franchise__decisive',
            label: 'Winner Takes All',
            subLabel: 'Results include winner takes all games in a series only',
            value: '',
            type: 'default',
            chip: 'Winner Takes All',
          },
          {
            id: 'game_franchise__ahead_in_series',
            label: 'Ahead in Series',
            subLabel: 'Results include games where a player’s team was ahead in a series only',
            value: '',
            type: 'default',
            chip: 'Ahead in Series',
          },
          {
            id: 'game_franchise__behind_in_series',
            label: 'Behind in Series',
            subLabel: 'Results include games where a player’s team was behind in a series only',
            value: '',
            type: 'default',
            chip: 'Behind in Series',
          },
          {
            id: 'game_franchise__tied_in_series',
            label: 'Tied in Series',
            subLabel: 'Results include games where a player’s team was tied in a series only',
            value: '',
            type: 'default',
            chip: 'Tied in Series',
          },
        ]
      },
    ]
  },
  {
    category: 'season',
    categoryLabel: 'Season Filters',
    filters: [
      {
        id: 'season_group',
        label: 'Current Season',
        subLabel: 'Results include Regular Season, Postseason and Play-In',
        searchTerms: [''],
        filterType: 'radio',
        chip: 'season',
        radioGroup: [
          {
            id: 'current_season',
            label: 'Current Season',
            subLabel: 'Results include Regular Season, Postseason and Play-In',
            value: CURRENT_SEASON,
            type: 'default',
            chip: 'Current Season',
          },
          {
            id: '3_point_era',
            label: '3-Point Era',
            subLabel: '1979-80 season to present',
            value: `1979-80 - ${CURRENT_SEASON}`,
            type: 'default',
            chip: '3-Point Era',
          },
          {
            id: 'since_merger',
            label: 'Since Merger',
            subLabel: '1976-77 season to present',
            value: `1976-77 - ${CURRENT_SEASON}`,
            type: 'default',
            chip: 'Since Merger',
          },
          {
            id: 'shot_clock_era',
            label: 'Shot Clock Era',
            subLabel: '1954-55 season to present',
            value: `1954-55 - ${CURRENT_SEASON}`,
            type: 'default',
            chip: 'Shot Clock Era',
          },
          {
            id: 'season_range',
            label: 'Season Range',
            subLabel: '',
            value: '',
            type: 'custom_season_range',
            chip: '[INPUT_VALUE] - [INPUT_END_VALUE]',
          },
        ]
      },
    ]
  },
  {
    category: 'holiday',
    categoryLabel: 'Holidays and Custom Date Filters',
    filters: [
      {
        id: 'holiday_group',
        label: 'Holidays',
        subLabel: '',
        searchTerms: [''],
        filterType: 'radio',
        chip: 'holidays',
        radioGroup: [
          {
            id: 'game__christmas_day',
            label: 'Christmas Day',
            subLabel: '',
            value: '',
            type: 'default',
            chip: 'Christmas',
          },
          {
            id: 'game__mlk_day',
            label: 'MLK Day',
            subLabel: '',
            value: '',
            type: 'default',
            chip: 'MLK Day',
          },
          {
            id: 'game__easter_sunday',
            label: 'Easter Sunday',
            subLabel: '',
            value: '',
            type: 'default',
            chip: 'Easter',
          },
          {
            id: 'game__new_years_day',
            label: "New Year's Day",
            subLabel: '',
            value: '',
            type: 'default',
            chip: "New Year's",
          },
          {
            id: 'game__presidents_day',
            label: "President's Day",
            subLabel: '',
            value: '',
            type: 'default',
            chip: "Presidents'",
          },
          {
            id: 'game__valentines_day',
            label: "Valentine's Day",
            subLabel: '',
            value: '',
            type: 'default',
            chip: "Valentine's",
          },
          {
            id: 'game__st_patricks_day',
            label: "St. Patrick's Day",
            subLabel: '',
            value: '',
            type: 'default',
            chip: "St. Patrick's",
          },
          {
            id: 'game__halloween',
            label: 'Halloween',
            subLabel: '',
            value: '',
            type: 'default',
            chip: 'Halloween',
          },
          {
            id: 'game__date',
            label: 'Custom Date Range',
            subLabel: '',
            value: '',
            type: 'custom_date_range',
            chip: '[INPUT_VALUE] - [INPUT_END_VALUE]',
          },
        ],
      },
    ],
  }
]





