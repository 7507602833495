import React from 'react';
import { Grid } from '@mui/material';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';
import FinderSelect from '../../atoms/FinderSelect/FinderSelect';
import {
  activeStatusOptions,
  homeAwayOptions,
  rookieStatusOptions,
  overtimeStatusOptions,
  FinderSelectOptionType,
  quarterHalfStatusOptions,
  gameTypeStatusOptions,
  DEFAULT_GAMETYPE_STATUS,
} from './constants';

interface IProps {
  showActive: boolean;
  showRookie: boolean;
  showHomeAway: boolean;
  showOvertime: boolean;
  hideNeutralSite: boolean;
  showGameType: boolean;
  showHalfQuarter: boolean;
  halfQuarterExplanationText?: string;
}

const GAMETYPE_EXPLANATION = "See Advanced Filters for tournament-specific search options";

function FastFilters({
  showActive,
  showRookie,
  showHomeAway,
  showOvertime,
  hideNeutralSite,
  showGameType,
  showHalfQuarter,
  halfQuarterExplanationText,
}: IProps) {
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();

  const handleActiveStatusChange = (selection: string) => {
    filterDispatchContext({
      type: 'activeStatusChange',
      activeStatus: selection,
      searchEnabled: true,
    });
  };

  const handleRookieStatusChange = (selection: string) => {
    filterDispatchContext({
      type: 'rookieStatusChange',
      rookieStatus: selection,
      searchEnabled: true,
    });
  };

  const handleHomeAwayChange = (selection: string) => {
    filterDispatchContext({
      type: 'homeAwayChange',
      homeAway: selection,
      searchEnabled: true,
    });
  };

  const handleOvertimeStatusChange = (selection: string) => {
    filterDispatchContext({
      type: 'overtimeStatusChange',
      overtimeStatus: selection,
      searchEnabled: true,
    });
  };

  const handleHalfQuarterStatusChange = (selection: string) => {
    filterDispatchContext({
      type: 'halfQuarterStatusChange',
      halfQuarterStatus: selection,
      searchEnabled: true,
    });
  };

  const handleGameTypeStatusChange = (selection: string) => {
    filterDispatchContext({
      type: 'gameTypeStatusChange',
      gameTypeStatus: selection,
      searchEnabled: true,
    });
  };

  const homeAwayOptionsFiltered = hideNeutralSite ?
    homeAwayOptions.filter((option: FinderSelectOptionType) => option.id !== 'neutral') :
    homeAwayOptions;

  return (
    <Grid
      container
      spacing={1}
      alignItems="flex-start"
      paddingX={2}
      paddingY={2}
    >
      {showGameType && <Grid item>
        <FinderSelect
          label='Game Type'
          value={filterContext.gameTypeStatus || DEFAULT_GAMETYPE_STATUS }
          options={gameTypeStatusOptions}
          handleSelection={handleGameTypeStatusChange}
          subHeaderText={GAMETYPE_EXPLANATION}
        />
      </Grid>}
      {
        showHalfQuarter && <Grid item>
        <FinderSelect
          label='Half/Quarter'
          value={filterContext.halfQuarterStatus || 'none'}
          options={quarterHalfStatusOptions}
          handleSelection={handleHalfQuarterStatusChange}
          subHeaderText={halfQuarterExplanationText}
        />
      </Grid>}
      {showActive && <Grid item>
        <FinderSelect
          label='Active Status'
          value={filterContext.activeStatus || 'any'}
          options={activeStatusOptions}
          handleSelection={handleActiveStatusChange}
        />
      </Grid>}
      {showRookie && <Grid item>
        <FinderSelect
          label='Rookie Status'
          value={filterContext.rookieStatus || 'any'}
          options={rookieStatusOptions}
          handleSelection={handleRookieStatusChange}
        />
      </Grid>}
      {showHomeAway && <Grid item>
        <FinderSelect
          label='Home/Away'
          value={filterContext.homeAway || 'any'}
          options={homeAwayOptionsFiltered}
          handleSelection={handleHomeAwayChange}
        />
      </Grid>}
      {showOvertime && <Grid item>
        <FinderSelect
          label='Overtime'
          value={filterContext.overtimeStatus || 'any'}
          options={overtimeStatusOptions}
          handleSelection={handleOvertimeStatusChange}
        />
      </Grid>}
    </Grid>
  );
}

export default FastFilters;
