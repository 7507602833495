import * as React from 'react';

function AELogoIcon() {
  return ( 
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>AE Logo Icon</title>
      <rect width="44" height="44" rx="6" fill="url(#paint0_linear_1917_39523)"/>
      <path
        d="M18.9354 31.4C18.7754 31.4 18.6714 31.32 18.6234 31.16L17.9034 28.808C17.8714 28.744 17.8314 28.712 17.7834 28.712H11.8794C11.8314 28.712 11.7914 28.744 11.7594 28.808L11.0634 31.16C11.0154 31.32 10.9114 31.4 10.7514 31.4H7.70343C7.60743 31.4 7.53543 31.376 7.48743 31.328C7.43943 31.264 7.43143 31.176 7.46343 31.064L12.6474 14.84C12.6954 14.68 12.7994 14.6 12.9594 14.6H16.7274C16.8874 14.6 16.9914 14.68 17.0394 14.84L22.2234 31.064C22.2394 31.096 22.2474 31.136 22.2474 31.184C22.2474 31.328 22.1594 31.4 21.9834 31.4H18.9354ZM12.6234 25.952C12.6074 26.048 12.6394 26.096 12.7194 26.096H16.9434C17.0394 26.096 17.0714 26.048 17.0394 25.952L14.8794 18.824C14.8634 18.76 14.8394 18.736 14.8074 18.752C14.7754 18.752 14.7514 18.776 14.7354 18.824L12.6234 25.952ZM36.171 17.216C36.171 17.296 36.139 17.368 36.075 17.432C36.027 17.48 35.963 17.504 35.883 17.504H28.011C27.931 17.504 27.891 17.544 27.891 17.624V21.344C27.891 21.424 27.931 21.464 28.011 21.464H33.171C33.251 21.464 33.315 21.496 33.363 21.56C33.427 21.608 33.459 21.672 33.459 21.752V24.08C33.459 24.16 33.427 24.232 33.363 24.296C33.315 24.344 33.251 24.368 33.171 24.368H28.011C27.931 24.368 27.891 24.408 27.891 24.488V28.376C27.891 28.456 27.931 28.496 28.011 28.496H35.883C35.963 28.496 36.027 28.528 36.075 28.592C36.139 28.64 36.171 28.704 36.171 28.784V31.112C36.171 31.192 36.139 31.264 36.075 31.328C36.027 31.376 35.963 31.4 35.883 31.4H24.795C24.715 31.4 24.643 31.376 24.579 31.328C24.531 31.264 24.507 31.192 24.507 31.112V14.888C24.507 14.808 24.531 14.744 24.579 14.696C24.643 14.632 24.715 14.6 24.795 14.6H35.883C35.963 14.6 36.027 14.632 36.075 14.696C36.139 14.744 36.171 14.808 36.171 14.888V17.216Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1917_39523"
          x1="0"
          y1="0"
          x2="44"
          y2="-4.58659e-09"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D92D20"/>
          <stop offset="1" stopColor="#F04438"/>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default AELogoIcon;






