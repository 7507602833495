import { useQuery } from '@tanstack/react-query';
import { useSignOut } from 'react-auth-kit';
import { fetchConferences, fetchFranchiseDetail, fetchFranchiseHistory, fetchFranchiseSeason, fetchGameBoxScore, fetchPlayerDetail, fetchPlayers, fetchPlayerStats, fetchSearches, fetchTeams, fetchVenues } from '../../api/api';
import { ConferenceData, FranchiseDetailData, FranchiseHistoryData, FranchiseSeasonData, GameBoxScoreData, PlayerData, PlayerProfileData, RecentSearchData, TeamData, VenueData } from '../../api/types';
import { PlayerProfileStatsType } from '../types/player';

const PLAYER_MANIFEST_LIMIT = 100;

const formatPlayerFilter = (filter: string) => {
  const base = `limit=${PLAYER_MANIFEST_LIMIT}`;
  if (filter && filter.length > 0) {
    const formattedFilter = filter.trim().replace(' ', '+');
    return `${base}&name__istartswith=${formattedFilter}`;
  }
  return base;
};

export const useFetchPlayers = (filter: string, apiUrl: string) => {
  const formattedString = formatPlayerFilter(filter);
  const signOut = useSignOut();
  return useQuery<PlayerData>(
    ['PlayerInfo', formattedString],
    () => fetchPlayers(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const formatTeamOrOpponentFilter = (filter: string) => {
  if (filter && filter.length > 0) {
    const formattedFilter = filter.trim().replace(' ', '+');
    return `full_name__icontains=${formattedFilter}`;
  }
  return '';
};

export const useFetchTeams = (filter: string, apiUrl: string) => {
  const formattedString = formatTeamOrOpponentFilter(filter);
  const signOut = useSignOut();
  return useQuery<TeamData>(
    ['TeamInfo', formattedString],
    () => fetchTeams(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchOpponents = (filter: string, apiUrl: string) => {
  const formattedString = formatTeamOrOpponentFilter(filter);
  const signOut = useSignOut();
  return useQuery<TeamData>(
    ['OpponentInfo', formattedString],
    () => fetchTeams(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const formatConferenceFilter = (filter: string) => {
  if (filter && filter.length > 0) {
    const formattedFilter = filter.trim().replace(' ', '+');
    return `conference_name__icontains=${formattedFilter}`;
  }
  return '';
};

export const useFetchConferences = (filter: string, apiUrl: string) => {
  const formattedString = formatConferenceFilter(filter);
  const signOut = useSignOut();
  return useQuery<ConferenceData>(
    ['ConferenceInfo', formattedString],
    () => fetchConferences(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const formatVenueFilter = (filter: string) => {
  if (filter && filter.length > 0) {
    const formattedFilter = filter.trim().replace(' ', '+');
    return `venue_name__icontains=${formattedFilter}`;
  }
  return '';
};

export const useFetchVenues = (filter: string, apiUrl: string) => {
  const formattedString = formatVenueFilter(filter);
  const signOut = useSignOut();
  return useQuery<VenueData>(
    ['VenueInfo', formattedString],
    () => fetchVenues(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchFranchiseHistory = (franchiseId: string, apiUrl: string) => {
  const formattedString = `franchise=${franchiseId}&ordering=-season&limit=1000`;
  const signOut = useSignOut();
  return useQuery<FranchiseHistoryData>(
    ['FranchiseHistoryInfo', formattedString],
    () => fetchFranchiseHistory(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

// the ids in the franchise profile table do NOT map to the franchise id used everywhere else
// so send a filter param and select first returned object
export const useFetchFranchiseDetail = (franchiseId: string, apiUrl: string) => {
  const formattedString = `franchise=${franchiseId}`;
  const signOut = useSignOut();
  return useQuery<FranchiseDetailData>(
    ['FranchiseDetailInfo', formattedString],
    () => fetchFranchiseDetail(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchGameBoxScore = (gameId: string, apiUrl: string) => {
  const url = `${apiUrl}${gameId}/`;
  const signOut = useSignOut();
  return useQuery<GameBoxScoreData>(
    ['GameBoxScoreInfo', gameId],
    () => fetchGameBoxScore(signOut, url),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchFranchiseSeason = (franchiseId: string, season: string, apiUrl: string) => {
  const formattedString = `franchise=${franchiseId}&season=${season}`;
  const signOut = useSignOut();
  return useQuery<FranchiseSeasonData>(
    ['FranchiseSeasonInfo', formattedString],
    () => fetchFranchiseSeason(formattedString, signOut, apiUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchPlayerDetail = (playerId: string, apiUrl: string) => {
  const url = `${apiUrl}/${playerId}/`;
  const signOut = useSignOut();
  return useQuery<PlayerProfileData>(
    ['PlayerDetailInfo', playerId],
    () => fetchPlayerDetail(signOut, url),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchPlayerStats = (playerId: string, apiUrl: string) => {
  const url = `${apiUrl}/${playerId}/`;
  const signOut = useSignOut();
  return useQuery<PlayerProfileStatsType>(
    ['PlayerStatsInfo', playerId],
    () => fetchPlayerStats(signOut, url),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchRecentSearches = (favorites: boolean, page: number) => {
  const signOut = useSignOut();
  let params = '';
  if (favorites) params = `favorite=1`;
  return useQuery<RecentSearchData>(
    ['RecentSearchesInfo', params, page],
    () => fetchSearches(signOut, params, page),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};
