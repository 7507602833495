import theme from '../../utils/theme/theme';

export const CheckboxStyles = {
  paddingRight: 5
};

export const LabelStyles = {
  color: theme.palette.custom.grey,
  fontSize: '14px',
  fontWeight: 600,
};

export const SubLabelStyles = {
  color: theme.palette.custom.mediumDarkGrey,
  fontSize: '14px',
  marginLeft: '28px',
  marginTop: '-8px',
};