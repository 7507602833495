import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { TableCell, TableRow } from '@mui/material';
import FactCard from '../../atoms/FactCard/FactCard';
import StyledTable from '../../atoms/StyledTable/StyledTable';
// import PillButtonGroup from '../../atoms/PillButtonGroup/PillButtonGroup';
import {
  PlayerFranchiseType,
  PlayerProfileStatsType,
  PlayerProfileType
} from '../../types/player';
import ROUTER_URLS from '../../utils/router/urls';
import { percentToFixed } from '../../utils/common/string';

interface IPlayerRows {
  id: string | number,
  season: string,
  schoolName: string,
  schoolLink: React.ReactNode,
  minutesPlayed?: number,
  points?: number,
  offensiveRebounds?: string,
  defensiveRebounds?: string,
  rebounds?: number,
  assists?: number,
  blocks?: number,
  steals?: number,
  fgPct?: string,
  threePntFgPct?: string,
  ftPct?: string,
  gameFinderLink: React.ReactElement,
}

interface IProps {
  playerDetails: PlayerProfileType;
  statsData: PlayerProfileStatsType;
};

const seasonPerGameHeaders = [
  'Season',
  'School',
  'MP',
  'PTS',
  'OREB',
  'DREB',
  'REB',
  'AST',
  'BLK',
  'STL',
  'FG%',
  '3P%',
  'FT%',
  'Detail',
];

const seasonPerGameMap = {
  'Season': 'season',
  'School': 'schoolLink',
  'MP': 'minutesPlayed',
  'PTS': 'points',
  'OREB': 'offensiveRebounds',
  'DREB': 'defensiveRebounds',
  'REB': 'rebounds',
  'AST': 'assists',
  'BLK': 'blocks',
  'STL': 'steals',
  'FG%': 'fgPct',
  '3P%': 'threePntFgPct',
  'FT%': 'ftPct',
  'Detail': 'gameFinderLink',
};

const generateTeamName = (franchise: PlayerFranchiseType) => (
  (franchise.franchise_id === 999999) ? 'Career' : franchise.short_name
);

const generateTeamLink = (franchise: PlayerFranchiseType) => {
  const teamId = franchise.franchise_id;
  const name = franchise.short_name;
  if (teamId !== 999999) {
    if (name) {
      // only link if a team has a short_name, which is usually an indicator
      // that the team is a D1 NCAAM team
      return <a href={generatePath(ROUTER_URLS.ncaambb.team, { teamId })}>{name}</a>
    }
    return franchise.name;
  }
  return name;
};

const generateGameFinderLink = (playerId: number, playerName: string) => (
  <Link target="_blank" to={`${ROUTER_URLS.ncaambb.playerGameFinderResults.replace('/:searchId?', '')}?playerId=${playerId}&playerName=${playerName}`}>View Game-by-Game &#8594;</Link>
);

const mapStatsData = (data: PlayerProfileStatsType) => {
  const rows: IPlayerRows[] = [];

  const seasonData = data.seasons;

  // Player Season Rows
  Object.keys(seasonData).forEach((key: string) => {
    const seasonRow = seasonData[key];
    const perGameRow = seasonRow.per_game;
    const overallRow = seasonRow.overall;

    rows.push({
      id: key,
      season: seasonRow.season_display,
      schoolName: generateTeamName(seasonRow.franchise),
      schoolLink: generateTeamLink(seasonRow.franchise),
      minutesPlayed: perGameRow.minutes,
      points: perGameRow.points,
      offensiveRebounds: perGameRow.offensive_rebounds?.toFixed(1),
      defensiveRebounds: perGameRow.defensive_rebounds?.toFixed(1),
      rebounds: perGameRow.total_rebounds,
      assists: perGameRow.assists,
      blocks: perGameRow.blocks,
      steals: perGameRow.steals,
      fgPct: percentToFixed(overallRow.field_goal_percentage, 3),
      threePntFgPct: percentToFixed(overallRow.three_point_field_goal_percentage, 3),
      ftPct: percentToFixed(overallRow.free_throw_percentage, 3),
      gameFinderLink: generateGameFinderLink(data.player_id, data.name),
    });
  });

  // Player Franchise Overall
  const franchiseData = data.career;
  Object.keys(franchiseData).forEach((key: string, index: number) => {
    const careerData = franchiseData[key];
    const perGameRow = careerData.per_game;
    const overallRow = careerData.overall;

    rows.push({
      id: key,
      season: (index === 0) ? 'Overall' : '',
      schoolName: generateTeamName(careerData.franchise),
      schoolLink: generateTeamLink(careerData.franchise),
      minutesPlayed: perGameRow.minutes,
      points: perGameRow.points,
      offensiveRebounds: perGameRow.offensive_rebounds?.toFixed(1),
      defensiveRebounds: perGameRow.defensive_rebounds?.toFixed(1),
      rebounds: perGameRow.total_rebounds,
      assists: perGameRow.assists,
      blocks: perGameRow.blocks,
      steals: perGameRow.steals,
      fgPct: percentToFixed(overallRow.field_goal_percentage, 3),
      threePntFgPct: percentToFixed(overallRow.three_point_field_goal_percentage, 3),
      ftPct: percentToFixed(overallRow.free_throw_percentage, 3),
      gameFinderLink: generateGameFinderLink(data.player_id, data.name),
    });
  });

  return rows;
};

function PlayerLayout({ playerDetails, statsData }: IProps) {
  // const [seasonTab, setSeasonTab] = React.useState(0);
  // const [perGameTab, setPerGameTab] = React.useState(0);

  // const handleSeasonTabChange = (tab: number) => {
  //   setSeasonTab(tab);
  // };

  // const handlePerGameTabChange = (tab: number) => {
  //   setPerGameTab(tab);
  // };

  const franchises = (playerDetails.franchises || []);
  const currentTeam = franchises.find((franchise) => franchise.current);
  const tableData = mapStatsData(statsData);
  const homeTown = playerDetails.home_town;
  const homeState = playerDetails.home_state && playerDetails.home_state.length > 0 ? `, ${playerDetails.home_state}` : '';

  return (
    <Grid container direction="column" sx={{ margin: 0 }}>
      <Grid container direction="row" marginTop={5}>
        <Grid item>
          <Box sx={{ height: '100%', flexDirection: 'column' }} justifyContent='center'>
            <Typography component="h1" variant="h5" fontWeight="600">{playerDetails.name} #{playerDetails.uniform_number}</Typography>
            <Typography component="div" variant='body1'>
              {currentTeam ? generateTeamLink(currentTeam) : 'No Active Team'}, Men&apos;s Basketball
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="row" sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: 3, paddingTop: 0 }}>
        <Grid item xs={3}>
          <FactCard label='Position' values={[playerDetails.position]} />
          <FactCard label='Height' values={[playerDetails.height || 'unknown']} />
          <FactCard label='Weight' values={[playerDetails.weight]} />
        </Grid>
        <Grid item xs={3}>
          <FactCard label='Hometown' values={[`${homeTown}${homeState}`]} />
          <FactCard label='High School' values={[playerDetails.highschool]} />
        </Grid>
        <Grid item xs={3}>
          <FactCard label='School(s)' values={franchises.map((s: any) => generateTeamLink(s))} />
          <FactCard label='Class' values={[playerDetails.class]} />
        </Grid>
      </Grid>

      <Grid container mt={5} marginRight={5} mb={10}>
        <Grid item xs={12}>
          <StyledTable
            uniqueRowKey='id'
            headers={seasonPerGameHeaders}
            data={tableData}
            dataMap={seasonPerGameMap}
            labelHeaderRow={
              <TableRow>
                <TableCell colSpan={14}>
                  <h5 style={{ display: 'inline-block', fontSize: '1.4em', marginRight: 3 }}>Stats</h5>
                  {
                    // <PillButtonGroup selected={seasonTab} values={['Season', 'Conference']} onChange={handleSeasonTabChange} />
                    // <PillButtonGroup selected={perGameTab} values={['Per Game', 'Totals']} onChange={handlePerGameTabChange} />
                  }
                </TableCell>
              </TableRow>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PlayerLayout;
