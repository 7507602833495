import React from 'react';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

interface IProps {
  text: string;
  Icon: React.ReactNode;
  path: string;
  disabled?: boolean;
}

function NavListItem({ text, Icon, path, disabled = false }: IProps) {
  return (
    <ListItem disablePadding>
      <ListItemButton component={Link} to={path} disabled={disabled}>
        <ListItemIcon sx={{ minWidth: 44 }}>
          { Icon }
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
}

export default NavListItem;
