export interface EliasInputProps {
  id: string;
  value: string;
  onChange: (value: string) => void;
  focusedOption?: string;
  isInputValid?: boolean;
  isEditing?: boolean;
  ariaLabel: string;
  placeholder?: string;
}

export const stopClickParent = (event: React.MouseEvent<HTMLElement>) => {
  event.stopPropagation();
};

export const handleInputFocus = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
) => event.target.select();

export const handleInputChange = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  changeFunc: (value: string) => void
) => {
  event.stopPropagation();
  const targetValue = (event.target as HTMLInputElement).value;
  changeFunc(targetValue);
};
