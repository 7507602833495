// eslint-disable-next-line import/prefer-default-export
export const parseChipLabel = (
  chipAbbr: string,
  inputValue: string,
  endInputValue?: string,
  equalityOptionValue?: string,
): string => {
  let label = chipAbbr;
  label = label.replace('[INPUT_VALUE]', inputValue);
  label = label.replace('[INPUT_END_VALUE]', endInputValue || '');
  label = label.replace('[EQUALITY_VALUE]', equalityOptionValue || '');
  return label;
};
