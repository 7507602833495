import React from 'react';
import { Grid } from '@mui/material';
import BoxScoreTeamRow from './BoxScoreTeamRow';
import BoxScoreSplitStats from './BoxScoreSplitStats';
import BoxScoreHeader from './BoxScoreHeader';

interface ITeamProps {
  teamName: string;
  teamRecord: string;
  teamScore: number;
  teamLink: string;
  teamLogoUrl?: string;
  teamWin: boolean;
  firstHalfScore: number;
  secondHalfScore: number;
  overtimeScore?: number;
  rank?: number;
  rankType?: string;
};

interface IProps {
  data: {
    date: string;
    venue: string;
    numberOfOvertimes: number;
    home: ITeamProps;
    visitor: ITeamProps;
    tournamentName?: string;
    tournamentRound?: string;
  };
};

function BoxScore({ data }: IProps) {
  return (
    <>
      <BoxScoreHeader date={data.date} venue={data.venue} numberOfOvertimes={data.numberOfOvertimes} tournamentName={data.tournamentName} tournamentRound={data.tournamentRound} />
      <Grid container direction='row' paddingY={3}>
        <Grid item xs={6}>
          <Grid container direction='column'>
            <Grid item>
              <BoxScoreTeamRow
                key='visitor-team-tow'
                teamName={data.visitor.teamName}
                teamRecord={data.visitor.teamRecord}
                teamScore={data.visitor.teamScore}
                teamLink={data.visitor.teamLink}
                teamLogoUrl={data.visitor.teamLogoUrl}
                teamWin={data.visitor.teamWin}
                rank={data.visitor.rank}
                rankType={data.visitor.rankType}
              />
            </Grid>
            <Grid item>
              <BoxScoreTeamRow
                key='home-team-tow'
                teamName={data.home.teamName}
                teamRecord={data.home.teamRecord}
                teamScore={data.home.teamScore}
                teamLink={data.home.teamLink}
                teamLogoUrl={data.home.teamLogoUrl}
                teamWin={data.home.teamWin}
                rank={data.home.rank}
                rankType={data.home.rankType}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <BoxScoreSplitStats home={data.home} visitor={data.visitor} numberOfOvertimes={data.numberOfOvertimes} />
        </Grid>
      </Grid>
    </>
  );
}

export default BoxScore;
