const ncaaNotes = [
  'PTS: 1938-39',
  'REB: 1955-56',
  'AST: 1970-71',
  'FG: 1938-39',
  'FGA: 1955-56',
  'FT: 1938-39',
  'FTA: 1938-39',
  'FG3: 1986-87',
  'FG3A: 1986-87',
  'OREB: 1956-57',
  'DREB: 1956-57',
  'PF: 1938-39',
  'STL: 1973-74',
  'TO: 1973-74',
  'BLK: 1973-74',
  'DD: 1970-71',
  'TD: 1970-71',
  'Seeds: 1978-79',
].join(' ');

const bigEastNotes = [
  'PTS: 1979-80',
  'REB: 1979-80',
  'AST: 1979-80',
  'FG: 1979-80',
  'FGA: 1979-80',
  'FT: 1979-80',
  'FTA: 1979-80',
  'FG3: 1986-87',
  'FG3A: 1986-87',
  'OREB: 1982-83',
  'DREB: 1982-83',
  'PF: 1979-80',
  'STL: 1979-80',
  'TO: 1979-80',
  'BLK: 1979-80',
  'DD: 1979-80',
  'TD: 1979-80',
].join(' ');  

const accNotes = [
  'PTS: 1953-54',
  'REB: 1953-54',
  'AST: 1953-54',
  'FG: 1953-54',
  'FGA: 1953-54',
  'FT: 1953-54',
  'FTA: 1953-54',
  'FG3: 1982-83/1986-87',
  'FG3A: 1982-83/1986-87',
  'OREB: 1997-98',
  'DREB: 1997-98',
  'PF: 1953-54',
  'STL: 1975',
  'TO: 1979-80',
  'BLK: 1975',
  'DD: 1953-54',
  'TD: 1953-54',
].join(' ');

const backInTimeInfo = [
  {
    tournamentName: 'Regular Season',
    seasonCutoff: '2005-06',
    notes: '',
  },
  {
    tournamentName: 'NCAA Tournament',
    seasonCutoff: '1938-39',
    notes: ncaaNotes,
  },
  {
    tournamentName: 'NIT Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'CBI Tournament',
    seasonCutoff: '2007-08',
    notes: '',
  },
  {
    tournamentName: 'CIT Tournament',
    seasonCutoff: '2008-09',
    notes: '',
  },
  {
    tournamentName: 'Big East Tournament',
    seasonCutoff: '1979-80',
    notes: bigEastNotes,
  },
  {
    tournamentName: 'ACC Tournament',
    seasonCutoff: '1953-54',
    notes: accNotes,
  },
  {
    tournamentName: 'America East Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'A-Sun Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'A-10 Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'Big Sky Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'Big South Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'Big Ten Tournament',
    seasonCutoff: '1997-98',
    notes: '',
  },
  {
    tournamentName: 'Big XII Tournament',
    seasonCutoff: '1996-97',
    notes: '',
  },
  {
    tournamentName: 'Big West Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'CAA Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'C-USA Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'Horizon Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'MAAC Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'MAC Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'Summit Tournament / Mid-Continent',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'MEAC Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'MVC',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'Mountain West Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'NEC',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'OVC',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'PAC-12 / PAC-10',
    seasonCutoff: '1986-87',
    notes: '',
  },
  {
    tournamentName: 'Patriot League Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'SEC Tournament',
    seasonCutoff: '1998-99',
    notes: '',
  },
  {
    tournamentName: 'Southern Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'Southland Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'SWAC Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'Sun Belt Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'WCC Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'WAC Tournament',
    seasonCutoff: '2002-03',
    notes: '',
  },
  {
    tournamentName: 'Great West Tournament',
    seasonCutoff: '2009-10',
    notes: '',
  },
  {
    tournamentName: 'American Athletic Tournament',
    seasonCutoff: '2013-14',
    notes: '',
  },
  {
    tournamentName: 'Ivy Tournament',
    seasonCutoff: '2016-17',
    notes: '',
  },
  {
    tournamentName: 'Basketball Classic',
    seasonCutoff: '2021-22',
    notes: '',
  },
];

export default backInTimeInfo;







																
										
