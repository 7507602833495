import {
  ModalChipType,
  ModalFilterCategoriesType,
  ModalFilterType
} from "../types/modal";
import {
  // ContextAdvancedFiltersType,
  ContextEqualityNumericOptionType,
  ContextFilterType,
} from "../types/context";
import {
  DEFAULT_GAMETYPE_STATUS,
} from "../compounds/FastFilters/constants";
import { advancedModalFilterCategories } from '../pages/NcaaMbb/constants';
import { initialAdvancedFilters } from './filter-context-constants';
import { parseChipLabel } from '../compounds/ModalFilters/modal-filter-helpers';

export const translateGameTypeStatus = (
  state: any,
  action: any,
) => {
  // handles setting game type fast filter from gameTypeModalFilters
  // handles clearing values from gameTypeModalFilters
  const { apiFilters } = action;
  let gameTypeStatus = apiFilters.gameTypeStatus || DEFAULT_GAMETYPE_STATUS;

  if (!apiFilters[gameTypeStatus]) {
    const gameTypeModalFilters = apiFilters.gameTypeFilters;
    if (gameTypeModalFilters.ncaa_tournament) {
      gameTypeStatus = 'ncaa-tournament';
    } else if (gameTypeModalFilters.reg) {
      gameTypeStatus = 'regular-season';
    } else if (gameTypeModalFilters.conference_game) {
      gameTypeStatus = 'conference-game';
    } else if (gameTypeModalFilters.any_conference_tournament) {
      gameTypeStatus = 'conference-tournament';
    }
  }

  return {
    state: {...state},
    action: {
      ...action,
      gameTypeStatus,
    },
  }
};

const generateAdvancedRankFilter = (
  context: ContextFilterType,
  fastFilterStatusField: string,
): ContextEqualityNumericOptionType | null => {
  type ContextFilterTypekey = keyof typeof context;
  const key = fastFilterStatusField as ContextFilterTypekey;
  let equalityOption = '=';
  let value = '';
  let endValue;
  let selected = false;

  const rankingStatus = context[key] as string;

  switch ( rankingStatus ) {
    case 'number-one':
      value = '1';
      equalityOption = '=';
      selected = true;
      break;
    case 'top-5':
      value = '5';
      equalityOption = '<='
      selected = true;
      break;
    case 'top-10':
      value = '10';
      equalityOption = '<='
      selected = true;
      break;
    case 'ranked':
      value = '25';
      equalityOption = '<='
      selected = true;
      break;
    case 'unranked':
      value = '26';
      equalityOption = '>='
      selected = true;
      break;
    default:
      // returns below with default values from pre-switch above
      break;
  }

  return {
    selected,
    equalityOption,
    value,
    end_value: endValue,
  }
};


export const translateApRanksFromFastFilter = (
  initialState: any,
  initialAction: any,
// eslint-disable-next-line arrow-body-style
) => {
  // handles populating advanced modal AP ranks from fast filters
  // handles clearing rank fast-filters

  return {
    state: initialState,
    action: {
      ...initialAction,
      rankingStatus: 'any',
      oppRankingStatus: 'any',
      apiFilters: {
        ...initialAction.apiFilters,
        advancedFilters: {
          ...initialAdvancedFilters,
          ...initialAction.apiFilters.advancedFilters,
          team_ap_rank: generateAdvancedRankFilter(
            initialAction.apiFilters, 'rankingStatus'
          ),
          opponent_ap_rank: generateAdvancedRankFilter(
            initialAction.apiFilters, 'oppRankingStatus'
          ),
        }
      }
    },
  }
};

export const translateApRanksFromSelectedStats = (
  initialState: any,
  initialAction: any,
// eslint-disable-next-line arrow-body-style
) => {
  return {
    state: initialState,
    action: initialAction,
  }
};

export const translateApRanks = (
  initialState: any,
  initialAction: any,
// eslint-disable-next-line arrow-body-style
) => {
  // returns input if AP Ranks already present in advancedFilters
  // handles team/opponent AP ranks
  // handles translating from stats criteria and from fast filter
  // does not handle chip generation
  if (
    initialAction.apiFilters.advancedFilters.team_ap_rank &&
    initialAction.apiFilters.advancedFilters.opponent_ap_rank
  ) {
    return {
      state: {...initialState},
      action: {...initialAction},
    }
  };

  let { state, action } = translateApRanksFromFastFilter(initialState, initialAction);
  ({ state, action } = translateApRanksFromSelectedStats(state, action));

  return { state, action }
};

export const clearChips = (
  initialState: any,
  initialAction: any,
// eslint-disable-next-line arrow-body-style
) => {
  return {
    state: initialState,
    action: {
      ...initialAction,
      apiFilters: {
        ...initialAction.apiFilters,
        advancedFiltersChips: [],
        gameTypeFiltersChips: [],
        selectedStatsFilters: [],
      },
    }
  }
};

export const generateModalChips = (
  modalFilterContext: any,
  modalCategories: ModalFilterCategoriesType[],
// eslint-disable-next-line arrow-body-style
) => {
  const chips: ModalChipType[] = [];

  modalCategories.forEach(category => {
    category.filters.forEach((filter: ModalFilterType) => {
      if (filter.id === 'season_group') {
        // eslint-disable-next-line
        console.log('found it');
      };
      const localFilterContext = modalFilterContext[filter.id];
      switch (filter.filterType) {
        case 'radio':
          filter?.radioGroup?.forEach(radio => {
            const value = modalFilterContext[`${filter.id}_value`];
            const endValue = modalFilterContext[`${filter.id}_end_value`];
            if (radio.id === localFilterContext) {
              chips.push({
                id: radio.id,
                label: parseChipLabel(radio.chip, value, endValue, ''),
                radioGroupParent: filter.id,
                equality: '',
                value,
                endValue,
              });
            }
          });
          break;
        case 'equalityNumeric':
          if (localFilterContext?.selected) {
            chips.push({
              id: filter.id,
              // label: filter.chip,
              label: parseChipLabel(
                filter.chip,
                localFilterContext?.value,
                localFilterContext?.endValue,
                localFilterContext?.equalityOption,
              ),
              radioGroupParent: '',
              equality: localFilterContext?.equalityOption,
              endValue: localFilterContext?.endValue,
              value: localFilterContext?.value,
            });
          }
          break;
        case 'checkbox':
          if (localFilterContext?.selected) {
            chips.push({
              id: filter.id,
              label: parseChipLabel(
                filter.chip,
                localFilterContext?.value,
                localFilterContext?.endValue,
                localFilterContext?.equalityOption,
              ),
              radioGroupParent: '',
              equality: localFilterContext?.equalityOption,
              endValue: localFilterContext?.endValue,
              value: localFilterContext?.value,
            });
          }
          break;
        default:
          // no-op
          break;
      }
    });
  });

    return chips;
};


export const regenerateChips = (
  initialState: any,
  initialAction: any,
// eslint-disable-next-line arrow-body-style
) => {
  return {
    state: initialState,
    action: {
      ...initialAction,
      apiFilters: {
        ...initialAction.apiFilters,
        advancedFiltersChips: generateModalChips(
          initialAction.apiFilters.advancedFilters,
          advancedModalFilterCategories,
        ),
        gameTypeFiltersChips: [], // this is no longer use, so zero it out
        // selectedStatsFilters: generateModalChips(),
      },
    },
  }
};

export const translateFieldsFromGameTypeModalToAdvancedModal = (
  initialState: any,
  initialAction: any,
) => {
  // TODO: this
  // handle all the fields that moved from the Game Type modal to the
  // Advanced modal with AE30-1024
  const fields = [
    'season_group', 'season_group_value', 'season_group_end_value',
    'ncaa_tournament', 'third_place_game', 'play_in_round', 'round_of_64',
    'round_of_32', 'sweet_sixteen', 'elite_eight', 'semi_finals',
    'championship', 'conference_game',
  ];
  // TODO: figure out how to remove gameTypeFilters?
  // Is this enough?
  const {
    gameTypeFilters,
    ...otherApiFilters
  } = initialAction.apiFilters;

  const { advancedFilters: oldAdvancedFilters } = otherApiFilters;
  const newAdvancedFilters = {
    ...oldAdvancedFilters,
  };

  fields.forEach(field => {
    const value = gameTypeFilters[field];
    if (value !== undefined) {
      newAdvancedFilters[field] = value;
    }
  });


  return {
    state: {...initialState},
    action: {
      ...initialAction,
      apiFilters: {
        // ...initialAction.apiFilters,
        ...otherApiFilters,
        advancedFilters: {
          ...newAdvancedFilters
        }
      },
    },
  }
};

export const translateEnsureBaseAdvancedFilters = (
  initialState: any,
  initialAction: any,
// eslint-disable-next-line arrow-body-style
) => {
  return {
    state: {...initialState},
    action: {
      ...initialAction,
      apiFilters: {
        ...initialAction.apiFilters,
        advancedFilters: {
          ...initialAdvancedFilters,
          ...initialAction.apiFilters.advancedFilters,
        },
      },
    },
  };
};

export const translateNcaamAdvancedFilter = (
  initialState: any,
  initialAction: any,
) => {
  let state;
  let action;

  // first transform gets the initial input, subsequent transforms get the
  // output from the previous transform

  // First, translations for actual context
  ({ state, action } = translateGameTypeStatus(initialState, initialAction));
  ({ state, action } = translateApRanks(state, action));
  ({ state, action } = translateFieldsFromGameTypeModalToAdvancedModal(state, action));

  // TODO: why isn't this working for setting base advanced filters?
  // ({ state, action } = translateEnsureBaseAdvancedFilters(state, action));

  // Second, translations to clear and regenerate UI chips
  // ({ state, action } = clearChips(state, action));
  ({ state, action } = regenerateChips(state, action));

  return { state, action }
};
