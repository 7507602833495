import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ClickAwayListener } from '@mui/material';
import { EqualitySigns, EqualitySignType } from '../../utils/stats/stats-utils';
import {
  buttonGroupStyles,
  booleanButtonGroupStyles,
  buttonStyles,
} from './StyleOverrides';

interface EqualityButtonsProps {
  onButtonClick: (
    sign: EqualitySignType,
    filterId: string,
    filterAbbreviation: string,
  ) => void;
  filterId: string;
  filterAbbreviation: string;
  selected?: string;
  onClickAway?: () => void;
}

export function EliasEqualityButtons({
  onButtonClick,
  filterId,
  filterAbbreviation,
  selected,
  onClickAway,
}: EqualityButtonsProps) {
  const handleChange = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const handleClickAway = () => {
    if (onClickAway) onClickAway();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <ToggleButtonGroup
        value={selected}
        onChange={handleChange}
        exclusive
        aria-label="equality sign selection"
        sx={buttonGroupStyles}
      >
        <ToggleButton
          data-testid={`${filterId}-less-than-button`}
          key={`${filterId}-less-than-button`}
          value={EqualitySigns['<=']}
          aria-label="less than equal"
          onClick={() =>
            onButtonClick(EqualitySigns['<='], filterId, filterAbbreviation)
          }
          sx={buttonStyles}
        >
          {' '}
          {EqualitySigns['<=']}
        </ToggleButton>
        <ToggleButton
          data-testid={`${filterId}-equals-button`}
          key={`${filterId}-equals-button`}
          value={EqualitySigns['=']}
          aria-label="equal"
          onClick={() =>
            onButtonClick(EqualitySigns['='], filterId, filterAbbreviation)
          }
          sx={buttonStyles}
        >
          {EqualitySigns['=']}
        </ToggleButton>
        <ToggleButton
          data-testid={`${filterId}-greater-than-button`}
          key={`${filterId}-greater-than-button`}
          value={EqualitySigns['>=']}
          aria-label="greater than equal"
          onClick={() =>
            onButtonClick(EqualitySigns['>='], filterId, filterAbbreviation)
          }
          sx={buttonStyles}
        >
          {EqualitySigns['>=']}
        </ToggleButton>
      </ToggleButtonGroup>
    </ClickAwayListener>
  );
}

export function EliasBooleanButtons({
  onButtonClick,
  filterId,
  filterAbbreviation,
  selected = EqualitySigns['='],
  onClickAway,
}: EqualityButtonsProps) {
  const handleChange = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onButtonClick(EqualitySigns['='], filterId, filterAbbreviation);
  };

  const handleClickAway = () => {
    if (onClickAway) onClickAway();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <ToggleButtonGroup
        value={selected}
        onChange={handleChange}
        exclusive
        aria-label="equality sign selection"
        sx={booleanButtonGroupStyles}
      >
        <ToggleButton
          data-testid={`${filterId}-equals-button`}
          key={`${filterId}-equals-button`}
          value={EqualitySigns['=']}
          aria-label="equal"
          onClick={() =>
            onButtonClick(EqualitySigns['='], filterId, filterAbbreviation)
          }
          sx={buttonStyles}
        >
          {EqualitySigns['=']}
        </ToggleButton>
      </ToggleButtonGroup>
    </ClickAwayListener>
  );
}
