export type AppLeagueRoutesType = {
  playerGameFinderSearch: string;
  playerGameFinderResults: string;
  playerStreakFinderSearch: string;
  playerStreakFinderResults: string;
  playerSpanFinderSearch: string;
  playerSpanFinderResults: string;
  team: string;
  teamGameFinderSearch: string;
  teamGameFinderResults: string;
  teamSpanFinderSearch: string;
  teamSpanFinderResults: string;
  teamStreakFinderSearch: string;
  teamStreakFinderResults: string;
  player: string;
  game: string;
  dataInfo: string;
};

const ROUTER_URLS = {
  home: '/',
  dashboard: '/dashboard',
  login: '/login',
  changePassword: '/user/changePassword',
  nba: {
    dataInfo: '/nba/data-info',
    playerGameFinderSearch: '/tools/player-game-finder',
    playerGameFinderResults: '/tools/player-game-finder/results/:searchId?',
    playerStreakFinderSearch: '/tools/player-streak-finder',
    playerStreakFinderResults: '/tools/player-streak-finder/results/:searchId?',
    playerSpanFinderSearch: '/tools/player-span-finder',
    playerSpanFinderResults: '/tools/player-span-finder/results/:searchId?',
    teamDataInfo: '/nba/team-data-info',
    teamGameFinderSearch: '/tools/team-game-finder',
    teamGameFinderResults: '/tools/team-game-finder/results/:searchId?',
    teamSpanFinderSearch: '/tools/team-span-finder',
    teamSpanFinderResults: '/tools/team-span-finder/results/:searchId?',
    teamStreakFinderSearch: '/tools/team-streak-finder',
    teamStreakFinderResults: '/tools/team-streak-finder/results/:searchId?',
  },
  ncaambb: {
    dataInfo: '/ncaambb/data-info',
    game: '/ncaambb/game/:gameId',
    player: '/ncaambb/player/:playerId',
    playerGameFinderSearch: '/ncaambb/player-game-finder',
    playerGameFinderResults: '/ncaambb/player-game-finder/results/:searchId?',
    playerStreakFinderSearch: '/ncaambb/player-streak-finder',
    playerStreakFinderResults: '/ncaambb/player-streak-finder/results/:searchId?',
    playerSpanFinderSearch: '/ncaambb/player-span-finder',
    playerSpanFinderResults: '/ncaambb/player-span-finder/results/:searchId?',
    team: '/ncaambb/team/:teamId',
    teamDataInfo: '/ncaambb/team-data-info',
    teamGameFinderSearch: '/ncaambb/team-game-finder',
    teamGameFinderResults: '/ncaambb/team-game-finder/results/:searchId?',
    teamSpanFinderSearch: '/ncaambb/team-span-finder',
    teamSpanFinderResults: '/ncaambb/team-span-finder/results/:searchId?',
    teamStreakFinderSearch: '/ncaambb/team-streak-finder',
    teamStreakFinderResults: '/ncaambb/team-streak-finder/results/:searchId?',
  },
  wnba: {
    dataInfo: '/wnba/data-info',
    playerGameFinderSearch: '/wnba/player-game-finder',
    playerGameFinderResults: '/wnba/player-game-finder/results/:searchId?',
    playerStreakFinderSearch: '/wnba/player-streak-finder',
    playerStreakFinderResults: '/wnba/player-streak-finder/results/:searchId?',
    playerSpanFinderSearch: '/wnba/player-span-finder',
    playerSpanFinderResults: '/wnba/player-span-finder/results/:searchId?',
    teamDataInfo: '/wnba/team-data-info',
    teamGameFinderSearch: '/wnba/team-game-finder',
    teamGameFinderResults: '/wnba/team-game-finder/results/:searchId?',
    teamSpanFinderSearch: '/wnba/team-span-finder',
    teamSpanFinderResults: '/wnba/team-span-finder/results/:searchId?',
    teamStreakFinderSearch: '/wnba/team-streak-finder',
    teamStreakFinderResults: '/wnba/team-streak-finder/results/:searchId?',
  },
};

export default ROUTER_URLS;
