import React, { useEffect, useMemo, useState } from 'react';
import { InputAdornment, styled } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import PlaceIcon from '@mui/icons-material/Place';
import CancelRounded from '@mui/icons-material/CancelRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Box from '@mui/system/Box';
import { VenueInfo } from '../../../api/types';
import './VenueFilter.scss';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';
import { useFetchVenues } from '../../hooks/useFetch';
import SearchDebounce from '../../utils/common/search';

const StyledAutocomplete = styled(Autocomplete)((props: { textcolor: string, weight: number  }) => ({
  '.MuiInputBase-root': {
    paddingLeft: '35px !important',
    '.MuiAutocomplete-input': {
      color: props.textcolor,
      fontWeight: props.weight,
    },
    '.MuiAutocomplete-endAdornment': {    
      '.MuiAutocomplete-clearIndicator': {
        '.cancel-icon': {
          color: props.textcolor,
          visibility: 'visible',
        }
      }
    },
  },
}));

interface IProps {
  apiUrl: string;
}

function VenueFilter({ apiUrl, }: IProps) {
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();

  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [options, setOptions] = useState<VenueInfo[]>([]);
  const { data: venueData } = useFetchVenues(searchValue, apiUrl);

  const searchDebounce: SearchDebounce = useMemo(() => new SearchDebounce(), [ ]);

  // Clean up this debounce when it is unmounted
  useEffect(() => () => searchDebounce.clear(), [ searchDebounce ]);

  const handleChange = (_event: any, newValue: VenueInfo | null) => {
    setOptions(newValue ? [newValue, ...options] : options);
    filterDispatchContext({
      type: 'changeVenueObject',
      venueObject: newValue,
      searchEnabled: true,
    });
  };

  const handleInputChange = (
    _event: any,
    newInputValue: string,
  ) => {
    setInputValue(newInputValue);
    if (newInputValue !== inputValue) {
      searchDebounce.debounce(() => setSearchValue(newInputValue));
    }
  };

  const textColor = filterContext.venueObject && 
    filterContext.venueObject.venue ? '#175CD3' : '#101828';

  const fontweight = filterContext.venueObject && 
    filterContext.venueObject.venue ? 500 : 400;

  return (
    <Box>
      <StyledAutocomplete
        id="elias-venue-filter"
        data-testid="elias-venue-filter"
        options={venueData?.results ?? []}
        value={filterContext.venueObject}
        inputValue={inputValue}
        includeInputInList
        filterOptions={(x) => x}
        isOptionEqualToValue={(option: any) =>
          option.venue === filterContext.venueObject?.venue
        }
        noOptionsText="No matches found."
        getOptionLabel={(option: any) => option.venue_name}
        onChange={(event: any, value: any) => handleChange(event, value)}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField {...params} placeholder="Venue" InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <PlaceIcon className="place-icon" />
              </InputAdornment>
            ),
          }}/>
        )}
        renderOption={(props, option: any) => (
          <li {...props} key={option.venue}>
            {option.venue_name}
          </li>
        )}
        popupIcon={
          <ExpandMoreIcon />
        }
        forcePopupIcon={!filterContext.venueObject?.venue}
        clearIcon={
          <CancelRounded className='cancel-icon' />
        }
        textcolor={textColor}
        weight={fontweight}
      />
    </Box>
  );
}

export default VenueFilter;
