import { StatFilter, StatFilterType } from "../../../compounds/EliasStatsFilter/filter-stats-utils";

export const PAGE_TITLE = 'NBA Player Streak Finder';
export const PAGE_SUBTITLE =
  'Search the most complete and accurate NBA database for player game streaks';

export type FinderSelectOptionType = {
  id: string;
  label: string;
};

export const streakRangeOptions: FinderSelectOptionType[] = [
  { id: 'none', label: 'Any' },
  { id: 'single-season', label: 'Single Season' },
  { id: 'current', label: 'Current' },
  { id: 'current-single-season', label: 'Current (single season only)' },
  { id: 'start-career', label: 'Start of Career' },
  { id: 'start-season', label: 'Start of Season' },
];

export const DEFAULT_STREAK_RANGE_OPTION = streakRangeOptions[0].id;

export const statsFilters: StatFilter[] = [
  {
    id: 'team_win',
    abbr: 'WIN',
    name: 'Team Win',
    year: 1946,
    type: StatFilterType.boolean,
    explanation: '1 = The team won',
  },
  {
    id: 'team_loss',
    abbr: 'LOSS',
    name: 'Team Loss',
    year: 1946,
    type: StatFilterType.boolean,
    explanation: '1 = The team lost',
  },
  {
    id: 'points',
    abbr: 'PTS',
    name: 'Points',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'total_rebounds',
    abbr: 'REB',
    name: 'Rebounds',
    year: 1950,
    type: StatFilterType.equality,
  },
  {
    id: 'assists',
    abbr: 'AST',
    name: 'Assists',
    year: 1950,
    type: StatFilterType.equality,
  },
  {
    id: 'steals',
    abbr: 'STL',
    name: 'Steals',
    year: 1973,
    type: StatFilterType.equality,
  },
  {
    id: 'blocks',
    abbr: 'BLK',
    name: 'Blocks',
    year: 1973,
    type: StatFilterType.equality,
  },
  {
    id: 'double_double',
    abbr: 'DDBL',
    name: 'Double Double',
    year: 1950,
    type: StatFilterType.boolean,
  },
  {
    id: 'triple_double',
    abbr: 'TDBL',
    name: 'Triple Double',
    year: 1950,
    type: StatFilterType.boolean,
  },
  {
    id: 'field_goals_made',
    abbr: 'FG',
    name: 'Field Goals',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'field_goal_attempts',
    abbr: 'FGA',
    name: 'Field Goal Attempts',
    year: 1956,
    type: StatFilterType.equality,
  },
  {
    id: 'field_goal_percentage',
    abbr: 'FG%',
    name: 'Field Goal %',
    year: 1956,
    type: StatFilterType.equality,
    explanation: '.xxx format',
  },
  {
    id: 'free_throws_made',
    abbr: 'FT',
    name: 'Free Throws',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'free_throw_attempts',
    abbr: 'FTA',
    name: 'Free Throw Attempts',
    year: 1956,
    type: StatFilterType.equality,
  },
  {
    id: 'free_throw_percentage',
    abbr: 'FT%',
    name: 'Free Throw %',
    year: 1956,
    type: StatFilterType.equality,
    explanation: '.xxx format',
  },
  {
    id: 'three_point_field_goals_made',
    abbr: 'FG3M',
    name: 'Three Point Field Goals',
    year: 1979,
    type: StatFilterType.equality,
  },
  {
    id: 'three_point_field_goal_attempts',
    abbr: 'FG3A',
    name: 'Three Point FG Attempts',
    year: 1979,
    type: StatFilterType.equality,
  },
  {
    id: 'three_point_field_goal_percentage',
    abbr: 'FG3%',
    name: 'Three Point FG %',
    year: 1979,
    type: StatFilterType.equality,
    explanation: '.xxx format',
  },
  {
    id: 'turnovers',
    abbr: 'TO',
    name: 'Turnovers',
    year: 1977,
    type: StatFilterType.equality,
  },
  {
    id: 'personal_fouls',
    abbr: 'PF',
    name: 'Personal Fouls',
    year: 1950,
    type: StatFilterType.equality,
  },
  {
    id: 'started',
    abbr: 'GS',
    name: 'Game Started',
    year: 1970,
    type: StatFilterType.boolean,
    explanation: '1=Game Started, 0=Off-the-Bench',
  },
  {
    id: 'seconds',
    abbr: 'MIN',
    name: 'Minutes',
    year: 1955,
    type: StatFilterType.equality,
  },
  {
    id: 'plus_minus',
    abbr: '(+/-)',
    name: 'Plus/Minus',
    year: 1997,
    type: StatFilterType.equality,
  },
  {
    id: 'offensive_rebounds',
    abbr: 'OREB',
    name: 'Offensive Rebounds',
    year: 1973,
    type: StatFilterType.equality,
  },
  {
    id: 'defensive_rebounds',
    abbr: 'DREB',
    name: 'Defensive Rebounds',
    year: 1973,
    type: StatFilterType.equality,
  },
  {
    id: 'second_chance_points',
    abbr: 'PTS2',
    name: 'Second Chance Points',
    year: 1997,
    type: StatFilterType.equality,
  },
  {
    id: 'points_in_paint',
    abbr: 'PPTS',
    name: 'Points in Paint',
    year: 1997,
    type: StatFilterType.equality,
  },
  {
    id: 'dunks',
    abbr: 'DKS',
    name: 'Dunks',
    year: 1997,
    type: StatFilterType.equality,
  },
  {
    id: 'points_off_turnovers',
    abbr: 'PTO',
    name: 'Points off Turnovers',
    year: 1997,
    type: StatFilterType.equality,
  },
  {
    id: 'disqualified',
    abbr: 'DQ',
    name: 'Disqualified',
    year: 1950,
    type: StatFilterType.boolean,
  },
  {
    id: 'franchise_score',
    abbr: 'TM Score',
    name: 'Team Score',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'opponent_score',
    abbr: 'OPP Score',
    name: 'Opponent Score',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'game_franchise__score_difference',
    abbr: 'SC Diff',
    name: 'Score Difference',
    year: 1946,
    type: StatFilterType.equality,
  },
];
