import React, { useState } from "react";
import { Alert, Avatar, Box, Button, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useAuthUser, useIsAuthenticated, useSignIn } from 'react-auth-kit';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

import { doLogin, setDatadogUser } from '../../../api/auth';
import ROUTER_URLS from '../../utils/router/urls';
import PageTitle from "../PageTitle/PageTitle";


const getPostLoginPath = (location: any, user: any) => {
  let from = location.state?.from?.pathname || null;
  // eslint-disable-next-line no-console
  console.log(`from=${from} from location.state.from.pathname`);
  if (from === null || from === '/') {
    const defaultLeague = (user?.default_league || 'nba').toLowerCase();
    if (defaultLeague === 'wnba') {
      from = ROUTER_URLS.wnba.playerGameFinderSearch;
    } else if (defaultLeague === 'ncaam') {
      from = ROUTER_URLS.ncaambb.playerGameFinderSearch;
    } else {
      from = ROUTER_URLS.nba.playerGameFinderSearch;
    }
  }
  // eslint-disable-next-line no-console
  console.log(`from=${from} after check for default league`);

  return from;
}

function LogIn() {
  const isAuthenticated = useIsAuthenticated();
  const signIn = useSignIn();
  const authedUser = useAuthUser();
  const isAuthed = isAuthenticated();
  const navigate = useNavigate();
  const location = useLocation();
  const [ errorText, setErrorText ] = useState('');

  const successHandler = (response: any) => {
    const { data } = response;
    const { user } = data;
    const accessTokenExp = new Date(data.access_token_expiration || data.access_expiration);
    const refreshTokenExp = new Date(data.refresh_token_expiration || data.refresh_expiration);
    const now = Date.now().valueOf();

    const signInData = {
      token: data.access_token || data.access,
      expiresIn: accessTokenExp.valueOf() - now,
      tokenType: "Bearer",
      authState: user,
      refreshToken: data.refresh_token || data.refresh,
      refreshTokenExpireIn: refreshTokenExp.valueOf() - now,
    };
    setDatadogUser(user);
    signIn(signInData);

    const from = getPostLoginPath(location, user);
    navigate(from);
  };
  const errorHandler = (error: any) => {
    const errorMsg = error?.response?.data?.non_field_errors;
    setErrorText(errorMsg);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = (data.get('username') || '').toString();
    const password = (data.get('password') || '').toString();
    doLogin(username, password, successHandler, errorHandler);
  };

  if (isAuthed) {
    // Edge-case representing a user accidentally navigating to /login
    // outside of the normal flow while already logged in.
    const from = getPostLoginPath(location, authedUser);
    return (
      <Navigate to={from} state={{from: location}} replace/>
    );
  }

  return (
      <Container component="main" maxWidth="xs">
        <PageTitle title='AccessElias - Log In' />
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            { errorText &&
                <Alert severity="error">{ errorText }</Alert>
            }
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
  );
};

export default LogIn;
