import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';

interface IProps {
  text: string;
  path: string;
  disabled?: boolean;
}

function SubNavListItem({ text, path, disabled = false }: IProps) {
  return (
    <ListItemButton sx={{ pl: 7.5 }} component={Link} to={path} disabled={disabled}>
      <ListItemText primary={text} />
    </ListItemButton>    
  );
}

export default SubNavListItem;
