import { createTheme } from '@mui/material/styles';
import palette from './palette.module.scss';

const theme = createTheme({
  typography: {
    fontFamily: `"Inter", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
  palette: {
    primary: {
      main: palette.primary,
      darker: palette.primaryDarker,
      lighter: palette.primaryLighter,
    },
    neutral: {
      main: palette.neutral,
    },
    disabled: {
      button: palette.disabledButton,
    },
    custom: {
      darkGrey: palette.darkGrey,
      grey: palette.grey,
      greyBlack: palette.greyBlack,
      lightGrey: palette.lightGrey,
      lighterGrey: palette.lighterGrey,
      lightestGrey: palette.lightestGrey,
      lightMediumGrey: palette.lightMediumGrey,
      mediumGrey: palette.mediumGrey,
      mediumDarkGrey: palette.mediumDarkGrey,
      blueGrey: palette.blueGrey,
      mediumBlueGrey: palette.mediumBlueGrey,
      white: palette.white,
      black: palette.black,
    },
  },
});

// Typescript module augmentation
declare module '@mui/material/styles' {
  interface PaletteColor {
    lighter?: string;
    darker?: string;
    text?: string;
  }
  interface Palette {
    neutral: Palette['primary'];
    custom: CustomPaletteColorOptions;
    disabled: DisabledPalletteOptions;
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
    custom: CustomPaletteColorOptions;
    disabled: DisabledPalletteOptions;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
    darker?: string;
  }
  interface DisabledPalletteOptions {
    button: string;
  }
  interface CustomPaletteColorOptions {
    darkGrey: string;
    grey: string;
    greyBlack: string;
    lightGrey: string;
    lighterGrey: string;
    lightestGrey: string;
    lightMediumGrey: string;
    mediumGrey: string;
    mediumDarkGrey: string;
    blueGrey: string;
    mediumBlueGrey: string;
    white: string;
    black: string;
  }
}

export default theme;
