import React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFilterDispatchContext } from '../../contexts/FilterContext';
import ButtonStyles from './StyleOverrides';

interface BackButtonProps {
  route: string;
  onClick?: () => void;
}

function EliasBackButton({ onClick, route, }: BackButtonProps) {
  const navigate = useNavigate();
  const filterDispatchContext: any = useFilterDispatchContext();

  const handleBack = () => {
    if (onClick) onClick();

    filterDispatchContext({
      type: 'changeSearchStatus',
      searchEnabled: true,
    });
    navigate(route)
  };


  return (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
      <Button sx={ButtonStyles} onClick={handleBack}>
        {'<'} Back
      </Button>
    </Box>
  );
}

export default EliasBackButton;
